import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

export const API_BASE_URL = process.env.REACT_APP_API_ROOT;

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

//컨트롤 pc 목록 가져오기 get
export const controlpc = async (accessToken: string, refreshToken: string): Promise<any> => {
    const endPoint = `/api/version/control/pc`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//컨트롤 버전 현황 가져오기 get
export const controlstatus = async (accessToken: string, refreshToken: string): Promise<any> => {
  const endPoint = `/api/version/control/status`;
  const apiUrl = API_BASE_URL + endPoint;
  return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//클라이언트 버전 현황 가져오기 get
export const clientstatus = async (accessToken: string, refreshToken: string): Promise<any> => {
  const endPoint = `/api/version/client/status`;
  const apiUrl = API_BASE_URL + endPoint;
  return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//컨트롤 버전 변경하기 put
//pcSystemId : pc버전 verId:수정할 버전id
export const controlput = async (accessToken: string, refreshToken: string,pcSystemId : number,verId:number): Promise<any> => {
  const endPoint = `/api/version/control`;
  const apiUrl = API_BASE_URL + endPoint;
  const data = {pcSystemId,verId};
  return await sendPutRequest(apiUrl, accessToken, refreshToken,data);
}

//클라이언트 버전 변경하기 put
//pcSystemId : pc버전 verId:수정할 버전id
export const clientput = async (accessToken: string, refreshToken: string,pcSystemId : number,verId:number): Promise<any> => {
  const endPoint = `/api/version/client`;
  const apiUrl = API_BASE_URL + endPoint;
  const data = {pcSystemId,verId};
  return await sendPutRequest(apiUrl, accessToken, refreshToken,data);
}

//컨트롤 버전 목록 가져오기 get
//page : 페이지 size : 가져올 데이터 갯수 sort : 오름차순/내림차순 정렬
export const controlversion = async (accessToken: string, refreshToken: string,page :number,size: number,sort : string): Promise<any> => {
  const endPoint = `/api/version/control?page=${page}&size=${size}&sort=${sort}`;
  const apiUrl = API_BASE_URL + endPoint;
  return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//클라이언트 버전 목록 가져오기 get
//page : 페이지 size : 가져올 데이터 갯수 sort : 오름차순/내림차순 정렬
export const clientversion = async (accessToken: string, refreshToken: string,page :number,size: number,sort : string): Promise<any> => {
  const endPoint = `/api/version/client?page=${page}&size=${size}&sort=${sort}`;
  const apiUrl = API_BASE_URL + endPoint;
  return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//클라이언트 pc 목록 가져오기 get
export const borapc = async (accessToken: string, refreshToken: string,pcGroupId : string): Promise<any> => {
  const endPoint = `/api/version/client/pc?pcGroupId=${pcGroupId}`;
  const apiUrl = API_BASE_URL + endPoint;
  return await sendGetRequest(apiUrl, accessToken, refreshToken);
}
//클라이언트 전망대 목록 가져오기 get
export const boragroup = async (accessToken: string, refreshToken: string): Promise<any> => {
  const endPoint = `/api/version/client/group`;
  const apiUrl = API_BASE_URL + endPoint;
  return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//컨트롤 업로드하기 post
//file : 파일 filename : 파일명 comment : 코멘트 pcSystemIds : pc번호
export const upload = async (accessToken: string, refreshToken: string, file : File,fileName : string ,comment : string,pcSystemIds : string | string[] | undefined): Promise<any> => {
    const endPoint = '/api/version/control/upload';
    const apiUrl = API_BASE_URL + endPoint;
    const data = {file,fileName,comment,pcSystemIds};
    
    return await filepost(apiUrl, accessToken, refreshToken,data);
  };

//클라이언트  업로드하기 post
//file : 파일 filename : 파일명 comment : 코멘트 pcSystemIds : pc번호
  export const clientupload = async (accessToken: string, refreshToken: string, file : File,fileName : string ,comment : string,pcSystemIds : string | string[] | undefined): Promise<any> => {
    const endPoint = '/api/version/client/upload';
    const apiUrl = API_BASE_URL + endPoint;
    const data = {file,fileName,comment,pcSystemIds};
    
    return await filepost(apiUrl, accessToken, refreshToken,data);
  };


const filepost = async (apiUrl: string, accessToken: string, refreshToken: string,data : any): Promise<any> => {
    const response = await axios.post(apiUrl, data,{
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
        'Refresh-token': refreshToken,
      },
    });
    return response.data;
  };

  const sendGetRequest = async (apiUrl: string, accessToken: string, refreshToken: string): Promise<any> => {
    const response = await axios.get(apiUrl, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
            "Refresh-token": refreshToken,
        }
    });
    return response.data;
  };
const sendPutRequest = async (apiUrl: string, accessToken: string, refreshToken: string, data: {pcSystemId : number,verId : number}): Promise<any> => {
 
  const response = await axios.put(apiUrl, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      'Refresh-token': refreshToken,
    },
  });
  return response.data;
};