import React from "react";
import "../styles/common/common.css";

interface ContentsHeaderProps {
  title: string;
  breadcrumb: string[];
}

const ContentsHeader: React.FC<ContentsHeaderProps> = ({
  title,
  breadcrumb,
}) => {
  return (
    <div
      className="contents__header"
      style={{
        backgroundColor: "#f1f1f1",
        boxShadow: "0px 1px 2px 0px rgba(55, 64, 74, 0.15)",
        padding: "10px 25px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        position: "sticky",
        top: 64,
        zIndex: 1000,
      }}
    >
      <h4 style={{ fontWeight: "bold" }}>{title}</h4>
      <ul className="breadcrumb">
        {breadcrumb.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default ContentsHeader;
