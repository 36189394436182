import React, { useEffect, useState, ChangeEvent } from "react";
import JSZip from "jszip";
import "../styles/common/common.css";
import "../styles/common/style.css";

interface DataItem {
  pcSystemId: string;
  pcKorName: string;
  pcLocation: string;
}

interface Props {
  onSearch: (selectedOption: string | string[] | undefined, file: File, filename: string, comment: string) => void;
  children ?: any;
}

const ControlSearchBar: React.FC<Props> = ({ onSearch }) => {
  const [dataArray, setDataArray] = useState<DataItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("All");
  const [folderPath, setFolderPath] = useState<string>("");
  const [filename, setFilename] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  const getDataFromLocal = () => {
    const storedData = localStorage.getItem("controldata");
    if (storedData) {
      const parsedData: DataItem[] = JSON.parse(storedData);
      setDataArray(parsedData);
      setSelectedOption("All");
    }
  };

  const handleButtonClick = async () => {
    if ( selectedFiles) {
      const zip = new JSZip();
      Array.from(selectedFiles).forEach((file) => {
        zip.file(file.webkitRelativePath, file);
      });

      const content = await zip.generateAsync({ type: "blob" });
      const zipFile = new File([content], `${filename}.zip`, { type: "application/zip" });
      
      if(selectedOption === "All"){
        const allPcSystemIds = selectedOption === "All" ? dataArray.map(item => item.pcSystemId.toString()) : undefined;
        onSearch(allPcSystemIds, zipFile, filename, comment);
      }
      else {
        onSearch(selectedOption, zipFile, filename, comment);
      }
    }
    else {
      alert("파일을 선택해 주세요");
    }
  };

  useEffect(() => {
    getDataFromLocal();
  }, []);

  const placeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleFolderChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fullPath = e.target.files[0].webkitRelativePath;
      const folderName = fullPath.split("/").slice(0, -1).join("/");
      setFolderPath(folderName);
      setSelectedFiles(e.target.files);
    }
  };

  return (
    <form
      id="searchForm"
      className="form-horizontal"
      onSubmit={(e) => e.preventDefault()}
      autoComplete="off"
    >
      <div className="searchWrap" style={{ marginBottom: "10px" }}>
        <div className="lt">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>경로</th>
                <td>
                  <div style={{ display: "flex" }}>
                    {folderPath && <div style={{ marginTop: "8px" }}>{folderPath}</div>}
                    <button
                      className="logout-button"
                      type="button"
                      onClick={() => document.querySelector<HTMLInputElement>('input[type="file"]')?.click()}
                      style={{ marginRight: "10px" }}
                    >
                      폴더 선택
                    </button>
                    <input
                      type="file"
                      onChange={handleFolderChange}
                      style={{ display: "none" }}
                      directory=""
                      webkitdirectory=""
                      mozdirectory=""
                    />
                  </div>
                </td>
                <th>전망대 PC</th>
                <td>
                  <select
                    className="iptSelect"
                    value={selectedOption}
                    onChange={placeChange}
                  >
                    <option value="All">All</option>
                    {dataArray.map((item, index) => (
                      <option key={index} value={item.pcSystemId}>
                        {item.pcKorName} {item.pcLocation}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="searchWrap" style={{ marginBottom: "10px" }}>
        <div className="lt">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>파일명</th>
                <td>
                  <input
                    type="text"
                    placeholder="입력해주세요"
                    value={filename}
                    onChange={(e) => setFilename(e.target.value)}
                    style={{ marginRight: "10px", width: "140vh" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="searchWrap" style={{ marginBottom: "10px" }}>
        <div className="lt">
          <table>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>코멘트</th>
                <td style={{width:"100%"}}>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="입력해주세요"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      style={{ width: "100vh"}}
                    />
                    <button
                      className="logout-button"
                      style={{marginLeft : "auto"}}
                      onClick={handleButtonClick}
                    >
                      업로드
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </form>
  );
};

export default ControlSearchBar;
