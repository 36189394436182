import React, { useState, useEffect } from "react";
import Body from "../../components/Body.tsx";
import Pagination from '@mui/material/Pagination';
import { aqua, aquaphoto } from "../../apis/data/dataApi.ts";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import { getpcinfoclient } from "../../apis/dashboard/dashboardApi.ts";
import SearchBar from "../../components/AquaSearchBar.tsx";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

// AquaData 인터페이스 정의
interface AquaData {
    boraAquaPhotoId: number; // 사진의 ID
    downloadUrl: string; // 다운로드 URL
    fileName: string; // 파일 이름
    qrCodeUrl: string; // QR 코드 URL
}

const AquaQrcode: React.FC = () => {
    // 상태 변수 정의
    const [data, setData] = useState<AquaData[]>([]); // AquaData 배열 상태
    const [pageInfo, setPageInfo] = useState<any>(null); // 페이지 정보 상태
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호 상태
    const [startDate, setStartDate] = useState<string>(""); // 검색 시작 날짜 상태
    const [endDate, setEndDate] = useState<string>(""); // 검색 종료 날짜 상태
    const [selectedOption, setSelectedOption] = useState<string>(""); // 선택된 옵션 상태
    const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set()); // 선택된 항목 집합 상태
    let page = 0; // 페이지 번호를 저장하는 변수

    // 컴포넌트가 마운트될 때 실행되는 효과
    useEffect(() => {
        info(); // 초기 정보 로드 함수 호출

        // 이미지의 오른쪽 클릭 메뉴 비활성화
        const handleContextMenu = (e: MouseEvent) => {
            if ((e.target as HTMLElement).tagName === 'IMG') {
                e.preventDefault();
            }
        };
        document.addEventListener('contextmenu', handleContextMenu);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    // 초기 정보 로드 함수
    const info = async () => {
        const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
        const response = await getpcinfoclient(accessToken!, refreshToken!, "19"); // PC 정보 가져오기 API 호출
        localStorage.setItem("aquadata", JSON.stringify(response.data)); // 로컬 스토리지에 데이터 저장
        const currentDate = new Date(); // 현재 날짜 생성
        const yesterday = new Date(); // 어제 날짜 생성
        yesterday.setDate(currentDate.getDate() - 1); // 어제로 설정
        const yesterdayString = yesterday.toISOString().split("T")[0]; // ISO 형식으로 변환
        const amonth = new Date(yesterday); // 어제 날짜를 기준으로 한 달 전 날짜 생성
        amonth.setMonth(yesterday.getMonth() - 1); // 한 달 전으로 설정
        const amonthString = amonth.toISOString().split("T")[0]; // ISO 형식으로 변환
        handleSearchBarChange(amonthString, yesterdayString, response.data[0].pcSystemId); // 검색 바 변경 함수 호출
    };

    // 페이지 변경 핸들러
    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value); // 현재 페이지 번호 업데이트
        page = value - 1; // 페이지 변수 업데이트
        await handleSearchBarChange(startDate, endDate, selectedOption); // 검색 바 변경 함수 호출
    };

    // 검색 바 변경 핸들러
    const handleSearchBarChange = async (StartDate: string, EndDate: string, SelectedOption: string) => {
        setStartDate(StartDate); // 검색 시작 날짜 업데이트
        setEndDate(EndDate); // 검색 종료 날짜 업데이트
        setSelectedOption(SelectedOption); // 선택된 옵션 업데이트

        const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
        const ip = localStorage.getItem("ip"); // IP 가져오기
        if (accessToken && refreshToken && ip) {
            try {
                const response = await aqua(accessToken, refreshToken, page, 20, "ASC", SelectedOption, StartDate, EndDate, ip); // Aqua 데이터 API 호출
                setData(response.data.page); // 데이터 상태 업데이트
                setPageInfo(response.data.pageInfo); // 페이지 정보 상태 업데이트
            } catch (error) {
                alert("접근할 수 없는 ip입니다."); // 오류 처리
                window.history.back(); // 이전 화면으로 이동
            }
        }
    };

    // 체크박스 상태 변경 핸들러
    const handleCheckboxChange = (id: number) => {
        setSelectedItems(prev => {
            const updated = new Set(prev); // 이전 상태 복사
            if (updated.has(id)) {
                updated.delete(id); // 이미 선택된 항목이면 삭제
            } else {
                updated.add(id); // 선택된 항목이 아니면 추가
            }
            return updated; // 업데이트된 집합 반환
        });
    };

    // 아이템 클릭 핸들러
    const handleItemClick = (id: number) => {
        handleCheckboxChange(id); // 체크박스 상태 변경 함수 호출
    };

    // 다운로드 핸들러
    const handleDownload = async (selectedOptionleng: string) => {
        const selectedItemsArray = Array.from(selectedItems); // 선택된 항목 배열로 변환
        if (selectedItemsArray.length === 1) {
            const item = data.find((item: any) => item.boraAquaPhotoId === selectedItemsArray[0]); // 선택된 항목 찾기
            if (item) {
                navigator.clipboard.writeText(item.qrCodeUrl + selectedOptionleng); // 클립보드에 URL 복사
                alert("클립보드에 복사되었습니다"); // 알림 표시
                const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
                const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
                const ip = localStorage.getItem("ip"); // IP 가져오기
                if (accessToken && refreshToken && ip) {
                    const response = await aquaphoto(accessToken, refreshToken, selectedItemsArray[0], ip); // Aqua 사진 데이터 API 호출
                }
            }
        } else if (selectedItemsArray.length > 1) {
            alert("한 장의 사진만 선택해 주세요."); // 오류 처리
        }
    };

    return (
        <Body title="모니터링" breadcrumb={["홈", "모니터링", "아쿠아 QR코드 관리"]}>
            <SearchBar onSearch={handleSearchBarChange} handleDownload={handleDownload} /> {/* 검색 바 컴포넌트 */}
            <div
                className="box-container"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
            >
                <div className="image-grid">
                    {data.map((item, index) => (
                        <div key={index} className="image-item" onClick={() => handleItemClick(item.boraAquaPhotoId)}>
                            <input 
                                type="checkbox" 
                                checked={selectedItems.has(item.boraAquaPhotoId)} 
                                onChange={(e) => {
                                    e.stopPropagation(); // 이벤트 버블링 방지
                                    handleCheckboxChange(item.boraAquaPhotoId); // 체크박스 상태 변경 함수 호출
                                }} 
                                onClick={(e) => e.stopPropagation()} // 버블링 방지
                            />
                            <img 
                                src={item.downloadUrl} 
                                alt={item.fileName} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleItemClick(item.boraAquaPhotoId); // 아이템 클릭 핸들러 호출
                                }}
                                onContextMenu={(e) => e.preventDefault()} // 이미지 우클릭 방지
                            />
                            <p>{item.fileName}</p> {/* 파일 이름 표시 */}
                        </div>
                    ))}
                </div>
                {pageInfo && (
                    <div className="pagination-container">
                        <Pagination
                            count={pageInfo.totalRanges} // 총 페이지 수
                            page={currentPage} // 현재 페이지
                            onChange={handlePageChange} // 페이지 변경 핸들러
                            variant="outlined"
                            color="primary"
                        />
                    </div>
                )}
            </div>
        </Body>
    );
};

export default AquaQrcode;
