import React, { ReactNode, useEffect, useState } from "react";
import { addClientStaff } from "../../apis/user/userApi.ts";
import "../../styles/common/style.css";

interface AddModalProps {
  closeModal: () => void;
  updateinfo: (email: string) => void; // updateinfo로 명칭 변경
  children?: ReactNode | undefined;
}

const AddModal: React.FC<AddModalProps> = ({ closeModal, updateinfo }) => {
  const [email, setEmail] = useState<string>("");

  // 컴포넌트가 마운트될 때 클릭 이벤트 리스너 추가
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // 이메일 입력값 변경 시 호출
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // 제출 버튼 클릭 시 호출
  const handleSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken") || "";
    const refreshToken = localStorage.getItem("refreshToken") || "";

    // 이메일 형식 유효성 검사 정규 표현식
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      // 이메일 형식이 유효하지 않으면 경고 메시지 출력
      window.alert("이메일 형식이 유효하지 않습니다.");
      return;
    }
    if (window.confirm("계정을 추가하시겠습니까?")) {
      // 계정 추가 확인 시 API 호출
      await addClientStaff(accessToken, refreshToken, email);
      updateinfo(email); // updateinfo 호출로 변경
      setEmail(""); // 이메일 입력 필드 초기화
      closeModal(); // 모달 닫기
    }
  };

  // 모달 외부를 클릭했을 때 모달 닫기
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.classList.contains("modal-background")) {
      closeModal();
    }
  };

  return (
    <div className="modal-background">
      <div className="modal-content modal-scroll">
        <div className="card__container">
          <div className="tableWrap">
            <div className="modalinput">
              <input
                type="email"
                value={email}
                onChange={handleChange}
                placeholder="이메일을 입력하세요"
                required
              />
            </div>
            <div className="button-group">
              <button
                className="button-process"
                onClick={handleSubmit} // handleSubmit으로 변경
              >
                계정 추가
              </button>
              <button className="button-close" onClick={closeModal}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
