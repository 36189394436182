import React, { useEffect, useState } from "react";
import Body from "../../components/Body.tsx";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import "../../styles/dashboard/dashboard.css";
import {
  getnetwork,
  getheartbeat,
  gethardware,
} from "../../apis/monitoring/moniteringApi.ts";
export const API_BASE_URL = process.env.REACT_APP_WSS_ROOT as string;

// 네트워크 상태를 나타내는 인터페이스
interface NetworkStatus {
  pcSystemId: number;
  pcLocation: string;
  isConnected: boolean;
}

// 네트워크 데이터를 나타내는 인터페이스
interface NetworkData {
  pcGroupId: number;
  pcKorName: string;
  pcNetworkStatus: NetworkStatus[];
}

// 하드웨어 정보를 나타내는 인터페이스
interface HardwareInfo {
  pcGroupId: number;
  pcId: number;
  freeSpace: number;
  temperature: number;
}

const AdminNetwork: React.FC = () => {
  const title: string = "모니터링"; // 화면 제목
  const breadcrumb: string[] = ["홈", "모니터링", "네트워크 관리"]; // 경로 표시
  const webSocket: WebSocket = new WebSocket(API_BASE_URL + "/ws/bora"); // 웹소켓 연결
  const [data, setData] = useState<NetworkData[]>([]); // 네트워크 데이터 상태
  const [info, setInfo] = useState<HardwareInfo | null>(null); // 하드웨어 정보 상태

  useEffect(() => {
    webSocket.onopen = () => {
      // 웹소켓이 열리면 실행되는 함수
      const param = { actionType: "WEB", param: { userId: 1 } }; // 전송할 데이터
      webSocket.send(JSON.stringify(param)); // 웹소켓을 통해 데이터 전송
      network(); // 네트워크 데이터를 불러오는 함수 호출
    };

    webSocket.onmessage = (event) => {
      // 웹소켓을 통해 메시지를 받았을 때 실행되는 함수
      const receivedMessage = JSON.parse(event.data); // 받은 메시지를 JSON으로 파싱
      const pcSystemIdToUpdate = receivedMessage.pcSystemId; // 업데이트할 PC 시스템 ID
      const isConnectedToUpdate = receivedMessage.isConnect; // 업데이트할 연결 상태

      // 네트워크 데이터 상태를 업데이트
      setData((prevData) => {
        const newData = [...prevData]; // 기존 데이터를 복사
        newData.forEach((group) => {
          group.pcNetworkStatus.forEach((networkStatus) => {
            if (networkStatus.pcSystemId === pcSystemIdToUpdate) {
              networkStatus.isConnected = isConnectedToUpdate; // 해당 PC 시스템의 연결 상태를 업데이트
            }
          });
        });
        return newData; // 업데이트된 데이터를 반환
      });
    };
  }, []);

  const network = async () => {
    // 네트워크 데이터를 불러오는 함수
    try {
      const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
      const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
      const ip = localStorage.getItem("ip"); // IP 가져오기
      const networkData = await getnetwork(accessToken!, refreshToken!, ip!); // 네트워크 데이터 API 호출
      setData(networkData.data); // 불러온 데이터를 상태에 저장
    } catch (error) {
      console.error("Error fetching network data:", error); // 에러 발생 시 콘솔에 출력
    }
  };

  const updatePc = async (pcSystemId: number) => {
    // 특정 PC의 상태를 업데이트하는 함수
    try {
      const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
      const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
      const ip = localStorage.getItem("ip"); // IP 가져오기
      const heart = await getheartbeat(accessToken!, refreshToken!, pcSystemId, ip!); // PC의 하트비트 데이터 API 호출
      const pcSystemIdToUpdate = pcSystemId; // 업데이트할 PC 시스템 ID
      const isConnectedToUpdate = heart.data.isConnected; // 업데이트할 연결 상태
      setData((prevData) => {
        const newData = [...prevData]; // 기존 데이터를 복사
        newData.forEach((group) => {
          group.pcNetworkStatus.forEach((networkStatus) => {
            if (networkStatus.pcSystemId === pcSystemIdToUpdate) {
              networkStatus.isConnected = isConnectedToUpdate; // 해당 PC 시스템의 연결 상태를 업데이트
            }
          });
        });
        return newData; // 업데이트된 데이터를 반환
      });
      const text = heart.data.pcKorName; // PC 이름
      const match = text.match(/\d+/); // PC 이름에서 숫자를 추출

      const hardware = await gethardware(accessToken!, refreshToken!, pcSystemId, ip!); // 하드웨어 데이터 API 호출
      setInfo({
        pcId: hardware.data.pcLocation,
        pcGroupId: hardware.data.pcGroupId,
        freeSpace: hardware.data.freeSpace,
        temperature: hardware.data.temperature,
      }); // 불러온 하드웨어 데이터를 상태에 저장
      alert(
        match
          ? `${match[0]}번 PC의 네트워크 상태를 업데이트했습니다.` // 숫자가 있는 경우
          : "PC의 네트워크 상태를 업데이트했습니다." // 숫자가 없는 경우
      );
    } catch (error) {
      console.error("Error updating PC:", error); // 에러 발생 시 콘솔에 출력
    }
  };

  const allUpdate = async () => {
    // 모든 PC의 상태를 업데이트하는 함수
    try {
      const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
      const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
      const ip = localStorage.getItem("ip"); // IP 가져오기
      const networkData = await getnetwork(accessToken!, refreshToken!, ip!); // 네트워크 데이터 API 호출
      setData(networkData.data); // 불러온 데이터를 상태에 저장
      alert("전체 PC의 네트워크 상태를 업데이트했습니다."); // 모든 PC의 상태를 업데이트한 후 알림
    } catch (error) {
      console.error("Error updating all PCs:", error); // 에러 발생 시 콘솔에 출력
    }
  };

  return (
    <Body title={title} breadcrumb={breadcrumb}>
      <div
        className="card-con"
        style={{ backgroundColor: "white", borderRadius: "1vh" }}
      >
        <div></div>
        {data.map((network, index) => (
          <React.Fragment key={index}>
            <div key={index} className="card-row">
              <div
                key={index}
                className={`network-card BLUE`}
                style={{ marginRight: "1vh", marginLeft: "1vh", border: "1vh solid #405189", width: "10vw", height: "18vh" }}
              >
                <div className="card-content">
                  <span className="card-text" style={{ fontSize: "2vh", top: "1vh", left: "1vh", lineHeight: "2vh"}}>{network.pcKorName}</span>
                  {info !== null && info.pcGroupId === network.pcGroupId && (
                    <>
                      <span
                        className="card-text"
                        style={{ top: "6.5vh", left: "1vh", fontSize: "2vh" }}
                      >
                        PC: {info.pcId}번PC
                      </span>
                      <span
                        className="card-text"
                        style={{ top: "9.5vh", left: "1vh", fontSize: "2vh" }}
                      >
                        온도: {info.temperature}도
                      </span>
                      <span
                        className="card-text"
                        style={{ top: "12.5vh", left: "1vh", fontSize: "2vh" }}
                      >
                        남은용량: {info.freeSpace}GB
                      </span>
                    </>
                  )}
                </div>
              </div>
              {network.pcNetworkStatus.map((status, statIndex) => (
                <div
                  key={statIndex}
                  className={`network-card ${
                    status.isConnected ? "GREEN" : "RED"
                  }`}
                  style={{ marginRight: "1vh", width: "9.9vw", height: "18vh" }}
                >
                  <div className="card-content">
                    <span className="card-text" style={{ color: "white", top: "1vh", left: "1vh", fontSize: "2vh" ,lineHeight: "2vh",}}>
                      {status.pcLocation}
                    </span>
                    <button onClick={() => updatePc(status.pcSystemId)}>
                      <img
                        src={
                          "https://bora-asset-resource.s3.ap-northeast-2.amazonaws.com/common/logo/logo_telescope.png"
                        }
                        alt="이미지"
                        style={{ width: "9vw", height: "16.3vh", borderRadius: "0.5vh" }}
                      ></img>
                    </button>
                  </div>
                </div>
              ))}
              {index === 0 && (
                <button className="network-button" onClick={() => allUpdate()}>
                  전체갱신
                </button>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    </Body>
  );
};

export default AdminNetwork;
