import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar.tsx";
import GroupSearchBar from "../../components/GroupSearchBar.tsx";
import ReactPlayer from "react-player";
import streaming from "../../assets/images/streaming.png";
import { getStreamings } from "../../apis/monitoring/moniteringApi.ts";

interface Streaming {
  path: string;
}

interface StreamingsResponse {
  result: boolean;
  data: {
    StreamingCnt: number;
    Streaming: Streaming[];
  };
}

const RealTimePc: React.FC = () => {
  const title: string = "모니터링";
  const breadcrumb: string[] = ["홈", "모니터링", "실시간 스트리밍"];
  const [url, setUrl] = useState<StreamingsResponse | null>(null);
  const [role, setRole] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");

  // 컴포넌트가 마운트될 때 역할과 스트리밍 URL 데이터를 로드
  useEffect(() => {
    const role: string | null = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }

    urlApi();
  }, []);

  // 검색 바 변경 시 호출되는 함수
  const handleSearchBarChange = async (selectedOption: string) => {
    const accessToken: string | null = localStorage.getItem("accessToken");
    const refreshToken: string | null = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      const response: StreamingsResponse = await getStreamings(
        accessToken,
        refreshToken,
        selectedOption
      );
      setUrl(response);
    }
  };

  // 스트리밍 URL 데이터를 API로부터 가져오는 함수
  const urlApi = async () => {
    const pcGroupId: string | null = localStorage.getItem("group");
    const accessToken: string | null = localStorage.getItem("accessToken");
    const refreshToken: string | null = localStorage.getItem("refreshToken");
    if (pcGroupId && accessToken && refreshToken) {
      const response: StreamingsResponse = await getStreamings(
        accessToken!,
        refreshToken!,
        pcGroupId
      );

      if (response.result === true) {
        setUrl(response);
      } else {
        alert("데이터 불러오기 실패");
      }
    }
  };

  // 선택된 옵션을 상태로 저장하는 함수
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <Body title={title} breadcrumb={breadcrumb}>
        <div>
          {role !== "" ? (
            role !== "ADMIN" ? (
              <div
                className="box-container"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
              >
                <div className="box-row">
                  <div className="box3">
                    <div className="box-label">1번 PC</div>
                    {url !== null ? (
                      url.data.StreamingCnt > 0 ? (
                        <ReactPlayer
                          height="95%"
                          url={url.data.Streaming[0].path}
                          controls
                          width="100%"
                          playing
                        />
                      ) : (
                        <img
                          src={streaming}
                          style={{
                            marginLeft: "200px",
                            marginTop: "50px",
                            width: "40%",
                          }}
                        />
                      )
                    ) : (
                      <img
                        src={streaming}
                        style={{
                          marginLeft: "200px",
                          marginTop: "50px",
                          width: "40%",
                        }}
                      />
                    )}
                  </div>
                  <span className="buttonSpacer"></span>
                  <div className="box3">
                    <div className="box-label">2번 PC</div>
                    {url !== null ? (
                      url.data.StreamingCnt > 1 ? (
                        <ReactPlayer
                          height="95%"
                          url={url.data.Streaming[1].path}
                          controls
                          width="100%"
                          playing
                        />
                      ) : (
                        <img
                          src={streaming}
                          style={{
                            marginLeft: "200px",
                            marginTop: "50px",
                            width: "40%",
                          }}
                        />
                      )
                    ) : (
                      <img
                        src={streaming}
                        style={{
                          marginLeft: "200px",
                          marginTop: "50px",
                          width: "40%",
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="box-row">
                  <div className="box3">
                    <div className="box-label" style={{ marginTop: "10px" }}>
                      3번 PC
                    </div>
                    {url !== null ? (
                      url.data.StreamingCnt > 2 ? (
                        <ReactPlayer
                          height="95%"
                          url={url.data.Streaming[2].path}
                          controls
                          width="100%"
                          playing
                        />
                      ) : (
                        <img
                          src={streaming}
                          style={{
                            marginLeft: "200px",
                            marginTop: "50px",
                            width: "40%",
                          }}
                        />
                      )
                    ) : (
                      <img
                        src={streaming}
                        style={{
                          marginLeft: "200px",
                          marginTop: "50px",
                          width: "40%",
                        }}
                      />
                    )}
                  </div>
                  <span className="buttonSpacer"></span>
                  <div className="box3">
                    <div className="box-label" style={{ marginTop: "10px" }}>
                      4번 PC
                    </div>
                    {url !== null ? (
                      url.data.StreamingCnt > 3 ? (
                        <ReactPlayer
                          height="95%"
                          url={url.data.Streaming[3].path}
                          controls
                          width="100%"
                          playing
                        />
                      ) : (
                        <img
                          src={streaming}
                          style={{
                            marginLeft: "200px",
                            marginTop: "50px",
                            width: "40%",
                          }}
                        />
                      )
                    ) : (
                      <img
                        src={streaming}
                        style={{
                          marginLeft: "200px",
                          marginTop: "50px",
                          width: "40%",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <GroupSearchBar onSearch={handleSearchBarChange} />
                <div className="container">
                  <div
                    className="box-container"
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                  >
                    <div className="box-row">
                      <div className="box3">
                        <div className="box-label">1번 PC</div>
                        {url !== null ? (
                          url.data.StreamingCnt > 0 ? (
                            <ReactPlayer
                              height="95%"
                              url={url.data.Streaming[0].path}
                              controls
                              width="100%"
                              playing
                            />
                          ) : (
                            <img
                              src={streaming}
                              style={{
                                marginLeft: "200px",
                                marginTop: "50px",
                                width: "40%",
                              }}
                            />
                          )
                        ) : (
                          <img
                            src={streaming}
                            style={{
                              marginLeft: "200px",
                              marginTop: "50px",
                              width: "40%",
                            }}
                          />
                        )}
                      </div>
                      <span className="buttonSpacer"></span>
                      <div className="box3">
                        <div className="box-label">2번 PC</div>
                        {url !== null ? (
                          url.data.StreamingCnt > 1 ? (
                            <ReactPlayer
                              height="95%"
                              url={url.data.Streaming[1].path}
                              controls
                              width="100%"
                              playing
                            />
                          ) : (
                            <img
                              src={streaming}
                              style={{
                                marginLeft: "200px",
                                marginTop: "50px",
                                width: "40%",
                              }}
                            />
                          )
                        ) : (
                          <img
                            src={streaming}
                            style={{
                              marginLeft: "200px",
                              marginTop: "50px",
                              width: "40%",
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="box-row">
                      <div className="box3">
                        <div
                          className="box-label"
                          style={{ marginTop: "10px" }}
                        >
                          3번 PC
                        </div>
                        {url !== null ? (
                          url.data.StreamingCnt > 2 ? (
                            <ReactPlayer
                              height="95%"
                              url={url.data.Streaming[2].path}
                              controls
                              width="100%"
                              playing
                            />
                          ) : (
                            <img
                              src={streaming}
                              style={{
                                marginLeft: "200px",
                                marginTop: "50px",
                                width: "40%",
                              }}
                            />
                          )
                        ) : (
                          <img
                            src={streaming}
                            style={{
                              marginLeft: "200px",
                              marginTop: "50px",
                              width: "40%",
                            }}
                          />
                        )}
                      </div>
                      <span className="buttonSpacer"></span>
                      <div className="box3">
                        <div
                          className="box-label"
                          style={{ marginTop: "10px" }}
                        >
                          4번 PC
                        </div>
                        {url !== null ? (
                          url.data.StreamingCnt > 3 ? (
                            <ReactPlayer
                              height="95%"
                              url={url.data.Streaming[3].path}
                              controls
                              width="100%"
                              playing
                            />
                          ) : (
                            <img
                              src={streaming}
                              style={{
                                marginLeft: "200px",
                                marginTop: "50px",
                                width: "40%",
                              }}
                            />
                          )
                        ) : (
                          <img
                            src={streaming}
                            style={{
                              marginLeft: "200px",
                              marginTop: "50px",
                              width: "40%",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </div>
      </Body>
    </>
  );
};

export default RealTimePc;
