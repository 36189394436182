import axios, { AxiosResponse } from 'axios';

export const API_BASE_URL = process.env.REACT_APP_API_ROOT;

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

//로그인 api post
//email : 이메일값 password : 비밀번호값
export const signin = async (email: string, password: string): Promise<AxiosResponse<any>> => {
  const endPoint = '/auth/signin';
  const apiUrl = API_BASE_URL + endPoint;
  return await sendPostRequest(apiUrl, email, password);
};

//회원가입 api post
//email : 변경할 이메일 password : 변경할패스워드
export const signup = async (email: string, password: string): Promise<AxiosResponse<any>> => {
  const endPoint = '/auth/signup';
  const apiUrl = API_BASE_URL + endPoint;
  return await sendPostRequest(apiUrl, email, password);
};
//클라이언트 부하 계정 추가하고 임시 비밀번호 메일 전송하기 post
//email : 이메일값
export const addClientStaff = async (accessToken: string, refreshToken: string, email: string): Promise<any> => {
  const endPoint = '/api/user/client-staff';
  const apiUrl = API_BASE_URL + endPoint;
  const data = { email };
  return await sendPostRequest2(apiUrl, accessToken, refreshToken, data);
};

//사용자 목록 가져오기 get
//pcGroupId : 전망대 그룹 아이디
export const getTempUsers = async (accessToken: string, refreshToken: string, pcGroupId: string): Promise<any> => {
  const endPoint = `/api/user?pcGroupId=${pcGroupId}`;
  const apiUrl = API_BASE_URL + endPoint;
  return await sendGetRequest(accessToken, refreshToken, apiUrl);
};

//비밀번호 변경하기 put
//password : 변경할비밀번호값
export const changePassword = async (accessToken: string, refreshToken: string, password: string): Promise<any> => {
  const endPoint = '/api/user/password';
  const apiUrl = API_BASE_URL + endPoint;
  const data = { password };
  return await sendPutRequest(apiUrl, accessToken, refreshToken, data);
};

//사용자 메모 수정하기 put
//userId : 계정id memo : 수정할 메모
export const reviseMemo = async (accessToken: string, refreshToken: string, userId: number, memo: string): Promise<any> => {
  const endPoint = '/api/user/memo';
  const apiUrl = API_BASE_URL + endPoint;
  const data = { userId, memo };
  return await sendPutRequest(apiUrl, accessToken, refreshToken, data);
};

//사용자 이메일 목록 가져오기 get
export const emailnotiget = async (accessToken: string, refreshToken: string): Promise<any> => {
  const endPoint = '/api/user/email';
  const apiUrl = API_BASE_URL + endPoint;
  return await sendGetRequest(accessToken, refreshToken,apiUrl);
};

//사용자 알림 활성화 및 비활성화 하기 put
//userId :계정id emailNoti : 활성화 여부
export const emailnotiput = async (accessToken: string, refreshToken: string,userId : number,emailNoti:boolean): Promise<any> => {
  const endPoint = `/api/user/email/noti`;
  const apiUrl = API_BASE_URL + endPoint;
  const data = {userId,emailNoti};
  return await sendPutRequest2(apiUrl, accessToken, refreshToken,data);
}

const sendPostRequest = async (apiUrl: string, email: string, password: string): Promise<AxiosResponse<any>> => {
  const data = { email, password };
  const response = await axios.post(apiUrl, data);
  return response;
};

const sendPostRequest2 = async (apiUrl: string, accessToken: string, refreshToken: string, data: { email: string }): Promise<any> => {
  const response = await axios.post(apiUrl, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      'Refresh-token': refreshToken,
    },
  });
  return response.data;
};

const sendGetRequest = async (accessToken: string, refreshToken: string, apiUrl: string): Promise<any> => {
  const response = await axios.get(apiUrl, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      'Refresh-token': refreshToken,
    },
  });
  return response.data;
};

const sendPutRequest = async (apiUrl: string, accessToken: string, refreshToken: string, data: { password?: string; userId?: number; memo?: string }): Promise<any> => {
  const response = await axios.put(apiUrl, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      'Refresh-token': refreshToken,
    },
  });
  return response.data;
};

const sendPutRequest2 = async (apiUrl: string, accessToken: string, refreshToken: string, data: {userId : number,emailNoti : boolean}): Promise<any> => {
 
  const response = await axios.put(apiUrl, data, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      'Refresh-token': refreshToken,
    },
  });
  return response.data;
};