import React, { useEffect, useState } from "react";
import "../../styles/dashboard/dashboard.css";
import "../../styles/dashboard/modal.css";
import Sidebar from "../../components/SideBar"; // SideBar import 수정
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar"; // 파일 확장자(.tsx) 제거
import { getAllPcsStatus } from "../../apis/dashboard/dashboardApi.ts";
import ErrorModal from "./ErrorModal.tsx";

interface PcData {
  pcGroupId: number;
  errorStatus: string;
  thumbnail: string;
  errorCnt: number;
  pcKorName: string;
}

const Dashboard: React.FC = () => {
  const title: string = "대시보드";
  const breadcrumb: string[] = ["대시보드"];

  const [pcsData, setPcsStatusData] = useState<PcData[] | null>(null);
  const [selectedPc, setSelectedPc] = useState<PcData | null>(null); // 선택된 PC 정보
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // 모달의 표시 여부


  //pc상태 불러오기
  const loadAllPcsStatus = async () => {
    const accessToken: string | null = localStorage.getItem("accessToken");
    const refreshToken: string | null = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      const data = await getAllPcsStatus(accessToken, refreshToken);
      setPcsStatusData(data.data);
    }
  };

  // 모달 열기
  const openModal = (pcGroupId: number) => {
    if (pcsData) {
      const selected = pcsData.find((elem) => elem.pcGroupId === pcGroupId);
      setSelectedPc(selected || null);
      setIsModalOpen(true);
    }
  };

  // 모달 닫기
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    
    loadAllPcsStatus();
  }, []);

  return (
    <Body title={title} breadcrumb={breadcrumb} > 
      {pcsData !== null && (
        <div className="card-container" style={{ backgroundColor: "white", borderRadius: "10px"}}>
          {pcsData.map((elem, index) => (
            <div key={index} className={`card ${elem.errorStatus}`}>
              <div className="card-content">
                {elem.errorStatus === "RED" ||
                elem.errorStatus === "YELLOW" ? (
                  <button
                    onClick={() =>
                      openModal(elem.pcGroupId)
                    }
                  >
                    <img
                      src={elem.thumbnail}
                      style={{ width: "100%" }}
                      alt=""
                    />
                    <div className="circle">
                      <span className="circle-text">{elem.errorCnt}</span>
                    </div>
                  </button>
                ) : (
                  <img
                    src={elem.thumbnail}
                    style={{ width: "100%" }}
                    alt=""
                  />
                )}
                <span className="card-text">{elem.pcKorName}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {isModalOpen && selectedPc && (
        <ErrorModal
          pcGroupId={selectedPc.pcGroupId}
          errorStatus={selectedPc.errorStatus}
          closeModal={closeModal}
        />
      )}
    </Body>
  );
};

export default Dashboard;
