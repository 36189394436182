import React, { useEffect, useState } from "react";
import "../../styles/common/common.css";
import "../../styles/common/style.css";
import Body from "../../components/Body.tsx";
import { emailnotiget, emailnotiput } from "../../apis/user/userApi.ts";

// 데이터 인터페이스 정의
interface Data {
    emailNoti: boolean; // 이메일 알림 여부
    memo: string;       // 메모
    email: string;      // 이메일 주소
    id: number;         // 사용자 ID
}

const Emailmanage: React.FC = () => {
    // 페이지 타이틀과 경로 설정
    const title: string = "계정관리";
    const breadcrumb: string[] = ["홈", "사용자관리", "이메일 발송 관리"];

    // 데이터 상태 변수 설정
    const [data, setData] = useState<Data[]>([]);

    // 컴포넌트가 마운트될 때 실행되는 useEffect 훅
    useEffect(() => {
        setting(); // 초기 설정 함수 호출
    }, []);

    // 초기 설정 함수
    const setting = async () => {
        const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기

        // 이메일 알림 정보 가져오기 API 호출
        const response = await emailnotiget(accessToken!, refreshToken!);
        setData(response.data); // 응답 데이터를 상태 변수에 설정
    };

    // 발송 여부 업데이트 함수
    const handleUpdateVersion = async (id: number) => {
        const confirmUpdate = window.confirm("발송 여부를 수정하시겠습니까?"); // 확인 메시지 표시
        if (!confirmUpdate) {
            return; // 사용자가 취소한 경우 함수 종료
        }

        const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기

        // 이메일 알림 여부 변경 API 호출
        const response = await emailnotiput(accessToken!, refreshToken!, id, !data.find(item => item.id === id)?.emailNoti);
        if (response.message !== "엔티티가 존재하지 않습니다.") {
            const response2 = await emailnotiget(accessToken!, refreshToken!); // 변경된 데이터 다시 가져오기
            setData(response2.data); // 상태 변수에 설정
        } else {
            alert("버전이 존재하지 않습니다."); // 오류 메시지 표시
        }
    };

    return (
        <Body title={title} breadcrumb={breadcrumb}>
            <div
                className="box-container"
                style={{ backgroundColor: "white", borderRadius: "10px" }}
            >
                <div className="tableWrap">
                    <div className="tableTop">
                        <ul className="tableTop__total">
                            <li>
                                계정 리스트 : <strong id="userCnt">{data.length}</strong> {/* 계정 리스트 개수 표시 */}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <table className="tableSet tableSet--type1">
                            <colgroup>
                                <col style={{ width: "5vw" }} />
                                <col style={{ width: "5vw" }} />
                                <col style={{ width: "20vw" }} />
                                <col style={{ width: "20vw" }} />
                                <col style={{ width: "20vw" }} />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>아이디</th>
                                    <th>이름</th>
                                    <th>이메일</th>
                                    <th>활성여부</th>
                                    <th>발송여부변경</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td> {/* 번호 */}
                                        <td>{item.id}</td> {/* 아이디 */}
                                        <td>{item.memo}</td> {/* 이름 */}
                                        <td>{item.email}</td> {/* 이메일 */}
                                        <td style={{ color: item.emailNoti ? '#405189' : 'red' }}>
                                            {item.emailNoti ? '활성화' : '비활성화'} {/* 활성 여부 */}
                                        </td>
                                        <td>
                                            <button className="center-button" onClick={() => handleUpdateVersion(item.id)}>
                                                발송 여부 변경
                                            </button> {/* 발송 여부 변경 버튼 */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {data.length === 0 && (
                            <div className="menagelabel">조회된 결과가 없습니다.</div>
                        )}
                    </div>
                </div>
            </div>
        </Body>
    );
};

export default Emailmanage;
