import React, { useState, useEffect } from "react";
import Body from "../../components/Body.tsx";
import { clientupload, clientversion, boragroup, clientput, clientstatus } from "../../apis/version/versionApi.ts";
import Loader from "../../components/Loader.tsx";
import BoraSearchBar from "../../components/BoraSearchBar.tsx";
import Pagination from '@mui/material/Pagination';

export const API_BASE_URL = process.env.REACT_APP_WSS_ROOT as string;

// 데이터 인터페이스 정의
interface Data {
    pcKorName: string;    // PC 한글 이름
    verId: string;        // 버전 ID
    pcLocation: string;   // PC 위치
    pcSystemId: number;   // PC 시스템 ID
    id: number;           // 데이터 ID
    fileName: string;     // 파일 이름
    comment: string;      // 코멘트
    path: string;         // 파일 경로
}

const BoraServer: React.FC = () => {
    // 상태 변수 정의
    const [data, setData] = useState<Data[]>([]);         // 데이터 배열 상태
    const [loading, setLoading] = useState<boolean>(false);  // 로딩 상태
    const [userCount, setUserCount] = useState<number>(0);   // 사용자 수 상태
    const [pageInfo, setPageInfo] = useState<any>(null);     // 페이지 정보 상태
    const [currentPage, setCurrentPage] = useState(1);       // 현재 페이지 번호 상태
    const [Pagesize, setPagesize] = useState(0);             // 페이지 크기 상태
    const [selectpage, setselectpage] = useState(0);         // 선택된 페이지 상태
    const [datastatus, setdatastatus] = useState<Data[]>([]); // 데이터 상태
    const [versiondata, setversiondata] = useState<Data[]>([]); // 버전 데이터 상태
    const [versionInputs, setVersionInputs] = useState<{ [key: number]: string }>({}); // 버전 입력 상태
    let page = 0; // 페이지 변수

    // 컴포넌트가 마운트될 때 실행되는 효과
    useEffect(() => {
        setting(); // 초기 설정 함수 호출
        pageselect(); // 페이지 선택 함수 호출
    }, []);

    // 초기 설정 함수
    const setting = async () => {
        const accessToken = localStorage.getItem("accessToken");    // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken");  // 리프레시 토큰 가져오기

        // 보라 그룹 데이터 가져오기
        const response1 = await boragroup(accessToken!, refreshToken!);
        localStorage.setItem("boragroupdata", JSON.stringify(response1.data));

        // 클라이언트 상태 데이터 가져오기
        const response2 = await clientstatus(accessToken!, refreshToken!);
        setdatastatus(response2.data);

        // 클라이언트 버전 데이터 가져오기
        const response3 = await clientversion(accessToken!, refreshToken!, page, 40, "ASC");
        setPagesize(response3.data.page.length);
        setversiondata(response3.data.page);
    };

    // 검색 바 변경 핸들러
    const handleSearchBarChange = async (
        SelectedOption: string | string[] | undefined,
        file: File,
        filename: string,
        comment: string
    ) => {
        setLoading(true); // 로딩 상태 설정
        const accessToken = localStorage.getItem("accessToken");    // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken");  // 리프레시 토큰 가져오기
        const ip = localStorage.getItem("ip");  // IP 가져오기
        try {
            // 클라이언트 업로드
            const response = await clientupload(accessToken!, refreshToken!, file, filename, comment, SelectedOption);
            setLoading(false); // 로딩 상태 해제
            pageselect(); // 페이지 선택 함수 호출
        } catch {
            setLoading(false); // 로딩 상태 해제
        }
    };

    // 페이지 변경 핸들러
    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value); // 현재 페이지 번호 설정
        page = value - 1; // 페이지 변수 업데이트
        pageselect(); // 페이지 선택 함수 호출
    };

    // 페이지 선택 함수
    const pageselect = async () => {
        const accessToken = localStorage.getItem("accessToken");    // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken");  // 리프레시 토큰 가져오기
        const ip = localStorage.getItem("ip");  // IP 가져오기
        if (accessToken && refreshToken && ip) {
            try {
                // 클라이언트 버전 데이터 가져오기
                const response = await clientversion(accessToken, refreshToken, page, 20, "ASC");
                setUserCount(response.data.pageInfo.totalItems); // 사용자 수 업데이트
                setPageInfo(response.data.pageInfo); // 페이지 정보 업데이트
                setData(response.data.page); // 데이터 업데이트
            } catch (error) {
                alert("접근할 수 없는 ip입니다."); // 오류 처리
                window.history.back(); // 이전 화면으로 이동
            }
        }
    };

    // 입력 변경 핸들러
    const handleInputChange = (id: number, value: string) => {
        setVersionInputs(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    // 버전 업데이트 핸들러
    const handleUpdateVersion = async (id: number, pcSystemId: number) => {
        const confirmUpdate = window.confirm("버전을 수정하시겠습니까?");
        if (!confirmUpdate) {
            return;
        }
        const newVersion = Number(versionInputs[id]);
        if (newVersion) {
            const accessToken = localStorage.getItem("accessToken");    // 액세스 토큰 가져오기
            const refreshToken = localStorage.getItem("refreshToken");  // 리프레시 토큰 가져오기
            const response = await clientput(accessToken!, refreshToken!, pcSystemId, newVersion); // 클라이언트 PUT 요청
            setVersionInputs(prevState => ({
                ...prevState,
                [id]: ''
            }));
            if(response.message !== "엔티티가 존재하지 않습니다."){
                const response2 = await clientstatus(accessToken!, refreshToken!); // 클라이언트 상태 데이터 다시 가져오기
                setdatastatus(response2.data); // 데이터 상태 업데이트
            } else {
                alert("버전이 존재하지 않습니다."); // 오류 처리
            }
        } else {
            alert("수정할 버전값을 넣어주세요."); // 오류 처리
        }
    };

    return (
        <Body title="모니터링" breadcrumb={["홈", "서버 관리", "보라 버전 관리"]}>
            {selectpage === 0 ? ( // 페이지 선택에 따라 렌더링
                <>
                <button
                    className="logout-button" style={{ marginLeft: "0vw", marginTop: "-1vh", marginBottom: "1vh", width : "8.5vw", justifyContent: "center" }}
                    onClick={() => { setselectpage(1) }}
                >
                    버전 관리탭으로 이동
                </button>
                <div className="search-bar-container">
                    {loading && (
                        <div className="search-bar-overlay">
                            <Loader />
                        </div>
                    )}
                    <BoraSearchBar onSearch={handleSearchBarChange} />
                </div>
                <div
                    className="box-container"
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                >
                    <div className="tableWrap">
                        <div className="tableTop">
                            <ul className="tableTop__total">
                                <li>
                                    총 버전수 : <strong id="userCnt">{userCount}</strong>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <table className="tableSet tableSet--type1">
                                <colgroup>
                                    <col style={{ width: "5vw" }} />
                                    <col style={{ width: "30vw" }} />
                                    <col style={{ width: "10vw" }} />
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>파일이름</th>
                                        <th>코멘트</th>
                                        <th>파일 경로</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.fileName}</td>
                                            <td>{item.comment}</td>
                                            <td>{item.path}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {userCount === 0 && (
                                <div className="menagelabel">조회된 결과가 없습니다.</div>
                            )}
                        </div>
                    </div>
                    {pageInfo && (
                        <div className="pagination-container">
                            <Pagination
                                count={pageInfo.totalRanges}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                color="primary"
                            />
                        </div>
                    )}
                </div>
                </>
            ) : (
                <>
                <button
                    className="logout-button" style={{ marginLeft: "0vw", marginTop: "-1vh", marginBottom: "1vh" , width : "8.5vw", justifyContent: "center" }}
                    onClick={() => { setselectpage(0) }}
                >
                    버전 업로드탭으로 이동
                </button>
                <div style={{ display: "flex" }}>
                    <div
                        className="box-container"
                        style={{ backgroundColor: "white", borderRadius: "10px", width: "50%" }}
                    >
                        <div className="tableWrap">
                            <div className="tableTop">
                                <ul className="tableTop__total">
                                    <li>
                                        버전 리스트 : <strong id="userCnt">{datastatus.length}</strong>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <table className="tableSet tableSet--type1">
                                    <colgroup>
                                        <col style={{ width: "15vw" }} />
                                        <col style={{ width: "5vw" }} />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>PC</th>
                                            <th>버전</th>
                                            <th>버전 수정</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datastatus.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.pcSystemId}번PC {item.pcKorName} {item.pcLocation}</td>
                                                <td>{item.verId}</td>
                                                <td>
                                                    <div style={{ display: "flex" }}>
                                                        <input
                                                            style={{ width: "10vw" }}
                                                            type="text"
                                                            placeholder="수정할 버전 번호를 적어주세요."
                                                            value={versionInputs[item.id] || ''}
                                                            onChange={(e) => handleInputChange(item.id, e.target.value)}
                                                        />
                                                        <button className="logout-button" onClick={() => handleUpdateVersion(item.id, item.pcSystemId)}>
                                                            버전 변경
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {userCount === 0 && (
                                    <div className="menagelabel">조회된 결과가 없습니다.</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="box-container"
                        style={{ backgroundColor: "white", borderRadius: "10px", width: "50%", marginLeft: "2%" }}
                    >
                        <div className="tableWrap">
                            <div className="tableTop">
                                <ul className="tableTop__total">
                                    <li>
                                        총 버전수 : <strong id="userCnt">{Pagesize}</strong>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <table className="tableSet tableSet--type1">
                                    <colgroup>
                                        <col style={{ width: "5vw" }} />
                                        <col style={{ width: "15vw" }} />
                                        <col style={{ width: "10vw" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>번호</th>
                                            <th>파일이름</th>
                                            <th>코멘트</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {versiondata.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.fileName}</td>
                                                <td>{item.comment}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {userCount === 0 && (
                                    <div className="menagelabel">조회된 결과가 없습니다.</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}
        </Body>
    );
};

export default BoraServer;
