import React, { useEffect, useState } from "react";
import ClientDashboard from "./ClientDashboard.tsx";
import AdminDashboard from "./AdminDashboard.tsx";

const Dashboard: React.FC = () => {
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    const userRole: string | null = localStorage.getItem("role");
    if (userRole) {
      setRole(userRole);
    }
  }, []);

  // 관리자인 경우 AdminDashboard, 클라이언트인 경우 ClientDashboard
  return <>{role === "ADMIN" ? <AdminDashboard /> : <ClientDashboard />}</>;
};

export default Dashboard;
