import React from "react";
import { Routes, Route } from "react-router-dom";
import TimeAndCount from "./pages/data/TimeAndCount.tsx";
import Dashboard from "./pages/dashboard/Dashboard.tsx";
import Login from "./pages/Login.tsx";
import Signup from "./pages/Signup.tsx";
import Label from "./pages/data/Label.tsx";
import Management from "./pages/user/management.tsx";
import VisitBook from "./pages/monitoring/VisitBook.tsx";
import RealTimePc from "./pages/monitoring/RealTimePc.tsx";
import AdminMonitoring from "./pages/monitoring/AdminMonitoring.tsx";
import Chating from "./pages/monitoring/Chating.tsx";
import AdminNetwork from "./pages/monitoring/AdminNetwork.tsx";
import AquaQrcode from "./pages/monitoring/AquaQrcode.tsx";
import ControlServer from "./pages/monitoring/ControlServer.tsx";
import BoraServer from "./pages/monitoring/BoraServer.tsx";
import Emailmanage from "./pages/user/Emailmanage.tsx";

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/TimeAndCount" element={<TimeAndCount />} />
        <Route path="/Label" element={<Label />} />
        <Route path="/Management" element={<Management />} />
        <Route path="/VisitBook" element={<VisitBook />} />
        <Route path="/RealTimePc" element={<RealTimePc />} />
        <Route path="/AdminMonitoring" element={<AdminMonitoring />} />
        <Route path="/Chating" element={<Chating />} />
        <Route path="/AdminNetwork" element={<AdminNetwork />} />
        <Route path="/AquaQrcode" element={<AquaQrcode />} />
        <Route path="/ControlServer" element={<ControlServer />} />
        <Route path="/BoraServer" element={<BoraServer />} />
        <Route path="/Emailmanage" element={<Emailmanage />} />
      </Routes>
    </div>
  );
};

export default App;
