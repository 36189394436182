import axios from 'axios';

export const API_BASE_URL = process.env.REACT_APP_API_ROOT as string;

//모든 전망대의 상태 값 가져오기 get
export const getAllPcsStatus = async (accessToken: string, refreshToken: string): Promise<any> => {
    const endPoint = '/api/dashboard/status';
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
}

//특정 전망대의 ERROR로그 가져오기 get
//pcGroupId : 전망대 그룹 아이디 errorStatus : 가져올상태값(Red or Yellow)
export const getErrorLogs = async (accessToken: string, refreshToken: string, pcGroupId: number, errorStatus: string): Promise<any> => {
    console.log(errorStatus);
    const endPoint = `/api/dashboard/error-logs?pcGroupId=${pcGroupId}&errorStatus=${errorStatus}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
}

//특정 전망대의 ERROR로그 GREEN으로 처리하기 put
//pcGroupId : 전망대 그룹 아이디
export const changeErrorLogs = async (accessToken: string, refreshToken: string, pcGroupId: number): Promise<any> => {
    const endPoint = `/api/dashboard/error-logs`;
    const apiUrl = API_BASE_URL + endPoint;
    return await put(apiUrl, accessToken, refreshToken, pcGroupId);
}

// 특정 전망대 별 pc 가져오기 get
export const getpcinfo = async (accessToken: string, refreshToken: string): Promise<any> => {
    const endPoint = '/api/bora/pc-v2';
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
}
// 특정 전망대 별 pc 가져오기(입력한 장소만 가져오기) get
//pcGroupId : 전망대 그룹 아이디
export const getpcinfoclient = async (accessToken: string, refreshToken: string, pcGroupId: string): Promise<any> => {
    const endPoint = `/api/bora/pc-v2?pcGroupId=${pcGroupId}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await get(apiUrl, accessToken, refreshToken);
}


const get = async (apiUrl: string, accessToken: string, refreshToken: string): Promise<any> => {
    const response = await axios.get(apiUrl, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
            "Refresh-token": refreshToken,
        }
    });

    return response.data;
}

const put = async (apiUrl: string, accessToken: string, refreshToken: string, pcGroupId: number): Promise<any> => {
    const response = await axios.put(apiUrl, pcGroupId, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
            "Refresh-token": refreshToken,
        }
    });
    return response.data;
}