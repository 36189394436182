import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar.tsx";
import "react-datepicker/dist/react-datepicker.css";
import { getData } from "../../apis/data/dataApi.ts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

// ChartComponent Props 정의
interface ChartComponentProps {
  title: string; // 차트 제목
  data: any[]; // 차트 데이터
  dataList: string[]; // 데이터 리스트
  colorArray: string[]; // 색상 배열
  useCustomLabel?: boolean; // 커스텀 라벨 사용 여부 (선택적)
}

// ChartComponent 컴포넌트 정의
const ChartComponent: React.FC<ChartComponentProps> = ({
  title,
  data,
  dataList,
  colorArray,
  useCustomLabel = false, // 기본값은 false
}) => {
  // 분을 시간과 분으로 변환하는 함수
  const convertMinutesToHoursAndMinutes = (minutes: number) => {
    if (minutes < 60) {
      return `${minutes}분`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}시간 ${remainingMinutes}분`;
    }
  };

  // 커스텀 라벨 렌더링 함수
  const renderCustomLabel = (props: any) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y - 10}
        fill="#666"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={12}
      >
        {convertMinutesToHoursAndMinutes(value)}
      </text>
    );
  };

  // 커스텀 툴팁 포맷터
  const customTooltipFormatter = (value: any) => {
    return convertMinutesToHoursAndMinutes(value);
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{ top: 70, right: 30, left: 20, bottom: 5 }}
      >
        <text
          x="50%"
          y="5%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="20"
          fill="#333"
        >
          {title}
        </text>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          formatter={useCustomLabel ? customTooltipFormatter : undefined}
        />
        <Legend wrapperStyle={{ marginLeft: "30px" }} />

        {dataList.map((item, index) => (
          <Bar
            key={index}
            dataKey={item}
            fill={colorArray[index % colorArray.length]}
          >
            {useCustomLabel && (
              <LabelList dataKey={item} content={renderCustomLabel} />
            )}
            {!useCustomLabel && <LabelList dataKey={item} position="top" />}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

// TimeAndCount 컴포넌트 정의
const TimeAndCount: React.FC = () => {
  const title = "시간 및 횟수 데이터"; // 페이지 제목
  const breadcrumb = ["홈", "데이터 조회", "시간 및 횟수 데이터"]; // 페이지 경로
  const [, setStartDate] = useState<string | null>(null); // 시작 날짜 상태
  const [, setEndDate] = useState<string | null>(null); // 종료 날짜 상태
  const [modeCntData, setModeCntData] = useState<any[] | null>(null); // 모드별 진입횟수 데이터 상태
  const [featureData, setFeatureData] = useState<any[] | null>(null); // 기능별 사용횟수 데이터 상태
  const [languageData, setlanguageData] = useState<any[] | null>(null); // 언어별 사용횟수 데이터 상태
  const [modeUsageData, setModeUsageData] = useState<any[] | null>(null); // 모드별 사용시간 데이터 상태
  const [, setSelectedOption] = useState<string>(""); // 선택된 옵션 상태
  const [selectedKor,] = useState<string>(""); // 선택된 한국어 상태
  const [modeList, setModeList] = useState<string[]>([]); // 모드 리스트 상태
  const [featureList, setFeatureList] = useState<string[]>([]); // 기능 리스트 상태
  const [languageList, setLanguageList] = useState<string[]>([]); // 언어 리스트 상태
  const [modeUsageList, setModeUsageList] = useState<string[]>([]); // 모드 사용 리스트 상태
  const [, setDataArray] = useState<any[]>([]); // 데이터 배열 상태
  const colorArray = [
    "#1f78b4",
    "#33a02c",
    "#e31a1c",
    "#ff7f00",
    "#6a3d9a",
    "#a6cee3",
    "#b2df8a",
    "#fb9a99",
  ]; // 색상 배열

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1); // 어제의 날짜 설정

  // 로컬 스토리지에서 데이터 가져오기
  const getDataFromLocal = () => {
    const storedData = localStorage.getItem("data");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setDataArray(parsedData);
      first(parsedData);
    }
  };

  // 데이터를 원하는 형식으로 가공하는 함수
  const processData = (data: any, property: string) => {
    const newdata = [
      {
        name: selectedKor,
        ...data[property],
      },
    ];
    return newdata;
  };

  // 검색바의 변경 이벤트 핸들러
  const handleSearchBarChange = async (
    StartDate: string,
    EndDate: string,
    SelectedOption: string
  ) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await getData(
      accessToken!,
      refreshToken!,
      SelectedOption,
      StartDate,
      EndDate
    );
    setModeList(Object.keys(response.data.mode));
    setFeatureList(Object.keys(response.data.feature));
    setLanguageList(Object.keys(response.data.language));
    setModeUsageList(Object.keys(response.data.modeUsage));
    const mode = processData(response.data, "mode");
    setModeCntData(mode);
    const language = processData(response.data, "language");
    setlanguageData(language);
    const feature = processData(response.data, "feature");
    setFeatureData(feature);
    const modeUsage = processData(response.data, "modeUsage");
    setModeUsageData(modeUsage);
  };

  // 첫 렌더링 시 로컬 데이터 가져오기
  useEffect(() => {
    getDataFromLocal();
  }, []);

  // 첫 번째 데이터 가져오기
  const first = async (data: any) => {
    const currentDate = new Date();
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const yesterdayString = yesterday.toISOString().split("T")[0];
    const amonth = new Date(yesterday);
    amonth.setMonth(yesterday.getMonth() - 1);
    const amonthString = amonth.toISOString().split("T")[0];
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    setStartDate(amonthString);
    setEndDate(yesterdayString);
    setSelectedOption(data[0].pcSystemId);

    const response = await getData(
      accessToken!,
      refreshToken!,
      data[0].pcSystemId,
      amonthString,
      yesterdayString
    );

    if (response.result === true) {
      setModeList(Object.keys(response.data.mode));
      setFeatureList(Object.keys(response.data.feature));
      setLanguageList(Object.keys(response.data.language));
      setModeUsageList(Object.keys(response.data.modeUsage));
      const mode = processData(response.data, "mode");
      setModeCntData(mode);
      const language = processData(response.data, "language");
      setlanguageData(language);
      const feature = processData(response.data, "feature");
      setFeatureData(feature);
      const modeUsage = processData(response.data, "modeUsage");
      setModeUsageData(modeUsage);
    } else {
      alert("데이터 불러오기 실패");
    }
  };

  return (
    <>
      <Body title={title} breadcrumb={breadcrumb}>
        <SearchBar onSearch={handleSearchBarChange} />
        <div className="container">
          <div
            className="box-container"
            style={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <div className="box-row">
              {modeCntData !== null ? (
                <div className="box">
                  <ChartComponent
                    title="모드별 진입횟수"
                    data={modeCntData}
                    dataList={modeList}
                    colorArray={colorArray}
                  />
                </div>
              ) : (
                <div className="box"></div>
              )}
              <span className="buttonSpacer"></span>
              {featureData !== null ? (
                <div className="box">
                  <ChartComponent
                    title="기능별 사용횟수"
                    data={featureData}
                    dataList={featureList}
                    colorArray={colorArray}
                  />
                </div>
              ) : (
                <div className="box"></div>
              )}
            </div>
            <div className="box-row">
              {modeUsageData !== null ? (
                <div className="box">
                  <ChartComponent
                    title="모드별 사용시간"
                    data={modeUsageData}
                    dataList={modeUsageList}
                    colorArray={colorArray}
                    useCustomLabel={true} // 커스텀 라벨 사용 여부 전달
                  />
                </div>
              ) : (
                <div className="box"></div>
              )}
              <span className="buttonSpacer"></span>
              {languageData !== null ? (
                <div className="box">
                  <ChartComponent
                    title="언어별 사용횟수"
                    data={languageData}
                    dataList={languageList}
                    colorArray={colorArray}
                  />
                </div>
              ) : (
                <div className="box"></div>
              )}
            </div>
          </div>
        </div>
      </Body>
    </>
  );
};

export default TimeAndCount;
