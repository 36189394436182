import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/common/style.css";
import "../styles/common/common.css";
import awesomeLogo from "../assets/images/awesomepia/logo.png";
import { signup } from "../apis/user/userApi.ts";

interface SignupResponse {
  data: {
    result: boolean;
  };
}

const Signup: React.FC = () => {
  const [loginId, setLoginId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const handleSignUp = async () => {
    const response: SignupResponse = await signup(loginId, password);

    if (response.data.result === true) {
      alert("이미 존재하는 계정입니다. 다른 아이디를 사용해주세요.");
    } else {
      navigate("/LoginPage");
    }
  };

  const handleLoginIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginId(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <div className="login">
      <div className="loginWrap" style={{ height: "45%" }}>
        <div className="header">
          <div className="logo">
            <a href="">
              <img src={awesomeLogo} alt="회사로고" />
            </a>
          </div>
        </div>
        <div className="loginWrap__ipt">
          <div className="txtBox">
            <p className="tit">보라 백오피스 회원가입</p>
          </div>
          <form
            id="saveForm"
            className="form-horizontal"
            method="post"
            autoComplete="off"
          >
            <div className="iptBox">
              <div className="row">
                <p className="tit">아이디</p>
                <input
                  type="text"
                  id="loginId"
                  name="loginId"
                  maxLength={30}
                  placeholder="아이디"
                  value={loginId}
                  onChange={handleLoginIdChange}
                />
              </div>
              <div className="row">
                <p className="tit">비밀번호</p>
                <input
                  type="password"
                  id="password"
                  name="password"
                  maxLength={20}
                  placeholder="비밀번호"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div className="row">
                <button
                  type="button"
                  className="loginBtn"
                  onClick={handleSignUp}
                >
                  회원가입
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="loginBg"></div>
    </div>
  );
};

export default Signup;
