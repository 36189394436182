import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/LabelSearchBar.tsx";
import Loader from "../../components/Loader.tsx";
import "react-datepicker/dist/react-datepicker.css";
import { getLabelData, pclabel } from "../../apis/data/dataApi.ts";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// 라벨 데이터 아이템 인터페이스
interface LabelDataItem {
  name: string;
  [key: string]: any;
}

// PC 데이터 인터페이스
interface PcData {
  pcGroupId: number;
  pcSystemId: number;
  pcKorname: string;
}

const Label: React.FC = () => {
  const title: string = "라벨 관련 데이터"; // 제목
  const breadcrumb: string[] = ["홈", "데이터 조회", "라벨 관련 데이터"]; // 탐색 경로
  const [labeldata, setLabelData] = useState<LabelDataItem[] | null>(null); // 라벨 데이터 상태
  const [firstgraph, setFirstGraph] = useState<number>(0); // 첫 번째 그래프 상태
  const [dataArray, setDataArray] = useState<PcData[]>([]); // PC 데이터 배열 상태
  const [secondgraph, setSecondGraph] = useState<number>(0); // 두 번째 그래프 상태
  const [thirdgraph, setThirdGraph] = useState<number>(0); // 세 번째 그래프 상태
  const [fourthgraph, setFourthGraph] = useState<number>(0); // 네 번째 그래프 상태
  const [fifthgraph, setFifthGraph] = useState<number>(0); // 다섯 번째 그래프 상태
  const [sixthgraph, setSixthGraph] = useState<number>(1); // 여섯 번째 그래프 상태
  const [loading, setLoading] = useState<boolean>(false); // 로딩 상태

  const yesterday: Date = new Date();
  yesterday.setDate(yesterday.getDate() - 1); // 어제의 날짜를 가져옴

  // 로컬 스토리지에서 데이터 가져오기
  const getDataFromLocal = async () => {
    setLoading(true); // 로딩 시작
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await pclabel(accessToken!, refreshToken!);
    if (response.data.length > 0) {
      setDataArray(response.data);
      first(response.data); // 첫 번째 그래프 데이터 설정
    }
  };

  useEffect(() => {
    getDataFromLocal(); // 컴포넌트가 마운트될 때 로컬 데이터 가져오기
  },  [setDataArray]);

  // pcSystemId를 통해 pcGroupId를 가져오기 위한 함수
  function getPcGroupIdBySystemId(pcSystemId: string) {
    const item = dataArray.find(
      (item) => item.pcSystemId === Number(pcSystemId)
    );
    const pcGroupId = item ? item.pcGroupId : null;
    return pcGroupId;
  }

  // 검색바의 변경 이벤트 핸들러
  const handleSearchBarChange = async (
    StartDate: string,
    EndDate: string,
    SelectedOption: string
  ) => {
    setLoading(true); // 로딩 시작
    const accessToken: string | null = localStorage.getItem("accessToken");
    const refreshToken: string | null = localStorage.getItem("refreshToken");

    try {
      if (SelectedOption != null && !isNaN(Number(SelectedOption))) {
        const GroupId: number | null = getPcGroupIdBySystemId(SelectedOption);
        const response: any = await getLabelData(
          accessToken!,
          refreshToken!,
          GroupId,
          SelectedOption,
          StartDate,
          EndDate
        );

        const data: LabelDataItem[] = [];

        Object.keys(response.data).forEach((labelType: string) => {
          response.data[labelType].forEach((item: any) => {
            const existingItem: LabelDataItem | undefined = data.find(
              (entry) => entry.name === item.labelKr
            );
            if (existingItem) {
              existingItem[labelType] = item.cnt;
            } else {
              const newItem: LabelDataItem = {
                name: item.labelKr,
                [labelType]: item.cnt,
              };
              data.push(newItem);
            }
          });
        });
        setLabelData(data); // 라벨 데이터 설정
        setLoading(false); // 로딩 끝
      }
    } catch {
      setLoading(false); // 로딩 끝
    }
  };

  // 첫 번째 그래프 데이터 설정
  const first = async (data: LabelDataItem[]) => {
    setLoading(true); // 로딩 시작
    const currentDate: Date = new Date();
    const yesterday: Date = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const yesterdayString: string = yesterday.toISOString().split("T")[0];
    const amonth: Date = new Date(yesterday);
    amonth.setMonth(yesterday.getMonth() - 1);
    const amonthString: string = amonth.toISOString().split("T")[0];
    const accessToken: string | null = localStorage.getItem("accessToken");
    const refreshToken: string | null = localStorage.getItem("refreshToken");
    const response: any = await getLabelData(
      accessToken!,
      refreshToken!,
      data[0].pcGroupId,
      data[0].pcSystemId,
      amonthString,
      yesterdayString
    );
    if (response.result === true) {
      const data: LabelDataItem[] = [];

      Object.keys(response.data).forEach((labelType: string) => {
        response.data[labelType].forEach((item: any) => {
          const existingItem: LabelDataItem | undefined = data.find(
            (entry) => entry.name === item.labelKr
          );
          if (existingItem) {
            existingItem[labelType] = item.cnt;
          } else {
            const newItem: LabelDataItem = {
              name: item.labelKr,
              [labelType]: item.cnt,
            };
            data.push(newItem);
          }
        });
      });
      setLabelData(data); // 라벨 데이터 설정
      setLoading(false); // 로딩 끝
    } else {
      setLoading(false); // 로딩 끝
      alert("데이터 불러오기 실패");
    }
  };

  return (
    <>
      <Body title={title} breadcrumb={breadcrumb}>
        <SearchBar onSearch={handleSearchBarChange} />
        <div
          className="box-container"
          style={{ backgroundColor: "white", borderRadius: "10px" }}
        >
          {loading ? ( // 로딩 중일 때
            <>
              <div style={{ marginTop: "10vh" }}></div>
              <Loader />
              <div className="timer-text" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10vh", marginBottom: "30vh" }}>Loading</div>
            </>
          ) : (
            <>
              {labeldata !== null ? ( // 라벨 데이터가 있을 때
                <div className="labelbox">
                  <ResponsiveContainer width={1530} height="100%">
                    <LineChart
                      width={500}
                      height={300}
                      data={labeldata}
                      margin={{
                        top: 5,
                        right: 50,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="name"
                        tick={{ fontSize: 9, fill: "black" }}
                        interval={0}
                      />
                      <YAxis type="number" domain={[0, "dataMax"]} />
                      <Tooltip contentStyle={{ fontSize: "12px" }} />
                      <Legend />
                      {firstgraph === 1 ? (
                        <Line
                          type="linear"
                          dataKey="맑은날 미니맵 라벨"
                          stroke="#ff0000"
                          activeDot={{ r: 8 }}
                        />
                      ) : (
                        <></>
                      )}
                      {secondgraph === 1 ? (
                        <Line
                          type="linear"
                          dataKey="맑은날 네비 라벨"
                          stroke="#ff7f0e"
                        />
                      ) : (
                        <></>
                      )}
                      {thirdgraph === 1 ? (
                        <Line
                          type="linear"
                          dataKey="맑은날 라벨"
                          stroke="#bcbd22"
                        />
                      ) : (
                        <></>
                      )}
                      {fourthgraph === 1 ? (
                        <Line type="linear" dataKey="XR 라벨" stroke="#8c564b" />
                      ) : (
                        <></>
                      )}
                      {fifthgraph === 1 ? (
                        <Line
                          type="linear"
                          dataKey="XR 네비 라벨"
                          stroke="#9467bd"
                        />
                      ) : (
                        <></>
                      )}
                      {sixthgraph === 1 ? (
                        <Line
                          type="linear"
                          dataKey="XR 미니맵 라벨"
                          stroke="#2ca02c"
                        />
                      ) : (
                        <></>
                      )}
                    </LineChart>
                  </ResponsiveContainer>
                  <div>
                    {firstgraph === 1 ? (
                      <button
                        className="logout-button"
                        style={{
                          display: "inline-block",
                          marginLeft: "390px",
                          marginRight: "20px",
                        }}
                        onClick={() => {
                          setFirstGraph((firstgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        맑은날 미니맵 라벨
                      </button>
                    ) : (
                      <button
                        className="label-button"
                        style={{
                          display: "inline-block",
                          marginLeft: "390px",
                          marginRight: "20px",
                        }}
                        onClick={() => {
                          setFirstGraph((firstgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        맑은날 미니맵 라벨
                      </button>
                    )}
                    {secondgraph === 1 ? (
                      <button
                        className="logout-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setSecondGraph((secondgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        맑은날 네비 라벨
                      </button>
                    ) : (
                      <button
                        className="label-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setSecondGraph((secondgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        맑은날 네비 라벨
                      </button>
                    )}
                    {thirdgraph === 1 ? (
                      <button
                        className="logout-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setThirdGraph((thirdgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        맑은날 라벨
                      </button>
                    ) : (
                      <button
                        className="label-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setThirdGraph((thirdgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        맑은날 라벨
                      </button>
                    )}
                    {fourthgraph === 1 ? (
                      <button
                        className="logout-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setFourthGraph((fourthgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        XR 라벨
                      </button>
                    ) : (
                      <button
                        className="label-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setFourthGraph((fourthgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        XR 라벨
                      </button>
                    )}
                    {fifthgraph === 1 ? (
                      <button
                        className="logout-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setFifthGraph((fifthgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        XR 네비 라벨
                      </button>
                    ) : (
                      <button
                        className="label-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setFifthGraph((fifthgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        XR 네비 라벨
                      </button>
                    )}
                    {sixthgraph === 1 ? (
                      <button
                        className="logout-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setSixthGraph((sixthgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        XR 미니맵 라벨
                      </button>
                    ) : (
                      <button
                        className="label-button"
                        style={{ display: "inline-block", marginRight: "20px" }}
                        onClick={() => {
                          setSixthGraph((sixthgraph as number) === 0 ? 1 : 0);
                        }}
                      >
                        XR 미니맵 라벨
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="labelbox"></div>
              )}
            </>
          )}
        </div>
      </Body>
    </>
  );
};

export default Label;
