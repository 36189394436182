import React, { useEffect, useState } from "react";
import "../styles/common/common.css";
import "../styles/common/style.css";
import { getgroup } from "../apis/monitoring/moniteringApi.ts";

interface GroupSearchBarProps {
  onSearch: (selectedOption: string) => void;
}

interface Group {
  id: string;
  pcKorName: string;
}

const GroupSearchBar: React.FC<GroupSearchBarProps> = ({ onSearch }) => {
  const [dataArray, setDataArray] = useState<Group[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const getDataFromLocal = async () => {
    const accessToken = localStorage.getItem("accessToken") || "";
    const refreshToken = localStorage.getItem("refreshToken") || "";
    const response = await getgroup(accessToken, refreshToken);
    setDataArray(response.data);
    setSelectedOption(response.data[0]?.id || "");
  };

  useEffect(() => {
    getDataFromLocal();
  }, []);

  const handleButtonClick = () => {
    if (selectedOption !== "PC") {
      onSearch(selectedOption);
    }
  };

  const placeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <form
      id="searchForm"
      className="form-horizontal"
      onSubmit={(e) => e.preventDefault()}
      autoComplete="off"
    >
      <div className="searchWrap">
        <div className="lt">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>전망대 PC</th>
                <td>
                  <select
                    className="iptSelect"
                    value={selectedOption}
                    onChange={placeChange}
                  >
                    {dataArray.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.pcKorName}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <button
            className="day-button"
            onClick={handleButtonClick}
            style={{ marginTop: "5px", width: "54px" }}
          >
            검색
          </button>
        </div>
      </div>
    </form>
  );
};

export default GroupSearchBar;
