import React, { useEffect, useState } from "react";
import {
  getErrorLogs,
  changeErrorLogs,
} from "../../apis/dashboard/dashboardApi.ts";
import { formatDate } from "../../utils/dateUtil.ts";

interface ErrorModalProps {
  pcGroupId: number;
  errorStatus: string;
  closeModal: () => void;
}

interface ErrorLog {
  errorId: string;
  comment: string;
  occurTime: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  pcGroupId,
  errorStatus,
  closeModal,
}) => {
  const [userData, setUserData] = useState<ErrorLog[]>([]);

  useEffect(() => {
    fetchErrorLogs();

    // Add event listener for outside click
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchErrorLogs = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const data = await getErrorLogs(
      accessToken!,
      refreshToken!,
      pcGroupId,
      errorStatus
    );

    setUserData(data.data);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if ((event.target as HTMLElement).classList.contains("modal-background")) {
      closeModal();
    }
  };

  const processError = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    await changeErrorLogs(accessToken!, refreshToken!, pcGroupId);

    window.alert("처리되었습니다.");
    closeModal();
    window.location.reload();
  };

  return (
    <div className="modal-background">
      <div className="modal-content modal-scroll">
        <div className="card__container">
          <div className="tableWrap">
            <div className="tableBody">
              <table className="tableSet tableSet--type1">
                <colgroup>
                  <col style={{ width: "60px" }} />
                  <col style={{ width: "60px" }} />
                  <col style={{ width: "500px" }} />
                  <col style={{ width: "300px" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>번호</th>
                    <th>코드</th>
                    <th>내용</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((error, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td style={{ textAlign: "center" }}>{error.errorId}</td>
                      <td style={{ textAlign: "center" }}>{error.comment}</td>
                      <td style={{ textAlign: "center" }}>
                        {formatDate(error.occurTime)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="button-group">
              <button
                className="button-process"
                onClick={() => {
                  if (window.confirm("정상 처리하시겠습니까?")) {
                    processError();
                  }
                }}
              >
                처리
              </button>
              <button className="button-close" onClick={closeModal}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
