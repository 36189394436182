import React, { useEffect, useState } from "react";
import awesomeLogo from "../assets/images/awesomepia/awesomelog.png";
import "../styles/common/style.css";
import "../styles/common/common.css";

const SideBar: React.FC = () => {
  const [activeDepth1, setActiveDepth1] = useState<number | null>(null);
  const [activeDepth2, setActiveDepth2] = useState<number | null>(null);
  const [activeDepth3, setActiveDepth3] = useState<number | null>(null);
  const [role, setRole] = useState<string>("");
  const [mainboard, setMainboard] = useState<number>(0);

  // Depth1 클릭 핸들러
  const handleDepth1Click = (index: number) => {
    setActiveDepth1((prevIndex) => (prevIndex === index ? null : index));
    setMainboard(0);
    localStorage.setItem("main", "0");
    localStorage.setItem("depth", activeDepth1 === index ? "" : String(index));
    if (activeDepth1 === index) {
      setActiveDepth3(0); 
      localStorage.setItem("depth3", "0");
    }
  };

  // Depth2 클릭 핸들러
  const handleDepth2Click = (index: number) => {
    setActiveDepth2(index);
    localStorage.setItem("depth2", String(index));
  };

  // Depth3 클릭 핸들러
  const handleDepth3Click = (index: number) => {
    setActiveDepth3((prevIndex) => (prevIndex === index ? null : index));
    localStorage.setItem("depth3", activeDepth3 === index ? "" : String(index));
  };

  // 대시보드 클릭 핸들러
  const handleDashboardClick = (index: number) => {
    localStorage.setItem("depth", String(index));
    localStorage.setItem("depth2", String(index));
    localStorage.setItem("depth3", String(index));
    localStorage.setItem("main", "1");
    localStorage.setItem("depth3", "0");
  };

  // 컴포넌트 마운트 시 로컬 스토리지에서 상태 초기화
  useEffect(() => {
    const role = localStorage.getItem("role");
    setRole(role || "");
    const depth = parseInt(localStorage.getItem("depth") || "0", 10);
    setActiveDepth1(depth);
    const depth2 = parseInt(localStorage.getItem("depth2") || "0", 10);
    setActiveDepth2(depth2);
    const depth3 = parseInt(localStorage.getItem("depth3") || "0", 10);
    setActiveDepth3(depth3);
    const board = parseInt(localStorage.getItem("main") || "0", 10);
    setMainboard(board);
  }, []);

  return (
    <div className="side">
      <div className="logo">
        <a href="/Dashboard" onClick={() => handleDashboardClick(0)}>
          <img src={awesomeLogo} alt="회사로고" />
        </a>
      </div>
      <ul className="sideGnb--depth1">
        {/* 대시보드 메뉴 */}
        <li className={`${mainboard === 1 ? "active" : ""}`}>
          <a href="/Dashboard" onClick={() => handleDashboardClick(0)}>
            <i className="xi-layout-snb-o"></i>대시보드
          </a>
        </li>

        {/* 데이터 조회 메뉴 */}
        <li className={`${activeDepth1 === 1 ? "toggleActive" : ""}`}>
          <p onClick={() => handleDepth1Click(1)}>
            <i className="xi-users"></i>데이터 조회
          </p>
          <ul className="sideGnb--depth2" style={{ display: activeDepth1 === 1 ? "block" : "none" }}>
            <li className={`${activeDepth2 === 1 ? "toggleActive" : ""}`}>
              <a href="/TimeAndCount" onClick={() => handleDepth2Click(1)}>
                시간 및 횟수 데이터
              </a>
            </li>
            <li className={`${activeDepth2 === 2 ? "toggleActive" : ""}`}>
              <a href="/Label" onClick={() => handleDepth2Click(2)}>
                라벨 관련 데이터
              </a>
            </li>
            <li className={`${activeDepth2 === 3 ? "toggleActive" : ""}`}>
              <a href="/Realtimepc" onClick={() => handleDepth2Click(3)}>
                실시간 스트리밍
              </a>
            </li>
          </ul>
        </li>

        {/* 관리자용 모니터링 메뉴 (관리자 역할일 때만 표시) */}
        {role === "ADMIN" && (
          <>
          <li className={`${activeDepth1 === 2 ? "toggleActive" : ""}`}>
            <p onClick={() => handleDepth1Click(2)}>
              <i className="xi-credit-card"></i>모니터링
            </p>
            <ul className="sideGnb--depth2" style={{ display: activeDepth1 === 2 ? "block" : "none" }}>
              <li className={`${activeDepth2 === 5 ? "toggleActive" : ""}`}>
                <a href="/AdminMonitoring" onClick={() => handleDepth2Click(5)}>
                  관리자용 모니터링
                </a>
              </li>
              <li className={`${activeDepth2 === 6 ? "toggleActive" : ""}`}>
                <a href="/AdminNetwork" onClick={() => handleDepth2Click(6)}>
                  네트워크 관리
                </a>
              </li>
              <li className={`${activeDepth2 === 9 ? "toggleActive" : ""}`}>
                <a href="/AquaQrcode" onClick={() => handleDepth2Click(9)}>
                  아쿠아 QR코드 관리
                </a>
              </li>
              
            </ul>
          </li>
          
          <li className={`${activeDepth1 === 5 ? "toggleActive" : ""}`}>
          <p onClick={() => handleDepth1Click(5)}>
            <i className="xi-tv"></i>서버 관리
          </p>
          <ul className="sideGnb--depth2" style={{ display: activeDepth1 === 5 ? "block" : "none" }}>
            <li className={`${activeDepth2 === 10 ? "toggleActive" : ""}`}>
              <a href="/ControlServer" onClick={() => handleDepth2Click(10)}>
                컨트롤 서버 관리
              </a>
            </li>
            <li className={`${activeDepth2 === 11 ? "toggleActive" : ""}`}>
              <a href="/BoraServer" onClick={() => handleDepth2Click(11)}>
                보라 서버 관리
              </a>
            </li>
            </ul>
        </li>
        
        </>
        )}

        {/* 사용자 관리 메뉴 */}
        <li className={`${activeDepth1 === 3 ? "toggleActive" : ""}`}>
          <p onClick={() => handleDepth1Click(3)}>
            <i className="xi-cloud"></i>사용자관리
          </p>
          <ul className="sideGnb--depth2" style={{ display: activeDepth1 === 3 ? "block" : "none" }}>
            <li className={`${activeDepth2 === 7 ? "toggleActive" : ""}`}>
              <a href="/Management" onClick={() => handleDepth2Click(7)}>
                계정관리
              </a>
            </li>
            <li className={`${activeDepth2 === 12 ? "toggleActive" : ""}`}>
              <a href="/Emailmanage" onClick={() => handleDepth2Click(12)}>
                이메일 발송 관리
              </a>
            </li>
          </ul>
        </li>

        {/* 고객 센터 메뉴 */}
        <li className={`${activeDepth1 === 4 ? "toggleActive" : ""}`}>
          <p onClick={() => handleDepth1Click(4)}>
            <i className="xi-man"></i>고객센터
          </p>
          <ul className="sideGnb--depth2" style={{ display: activeDepth1 === 4 ? "block" : "none" }}>
            <li className={`${activeDepth2 === 8 ? "toggleActive" : ""}`}>
              <a href="/Chating" onClick={() => handleDepth2Click(8)}>
                1:1문의
              </a>
            </li>
            
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
