import axios from 'axios';

export const API_BASE_URL = process.env.REACT_APP_API_ROOT as string;

//특정 전망대에 특정pc에 대한 실시간 영상 가져오기 get
//pcGroupId : 전망대 그룹 아이디
export const getStreamings = async (accessToken: string, refreshToken: string, pcGroupId: string | null): Promise<any> => {
    const endPoint = `/api/bora/streaming?pcGroupId=${pcGroupId}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//모든 전망대 그룹아이디 가져오기 get
export const getgroup = async (accessToken: string, refreshToken: string): Promise<any> => {
    const endPoint = `/api/bora/clients`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//전망대 pc 실시간 이미지 가져오기 ipget
//XForwardedFor : ip값
export const getframe = async (accessToken: string, refreshToken: string,XForwardedFor : string): Promise<any> => {
    const endPoint = `/api/monitoring/frame`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequestip(apiUrl, accessToken, refreshToken,XForwardedFor);
}

//쪽지 최신 목록 가져오기 get
export const getroomlist = async (accessToken: string, refreshToken: string): Promise<any> => {
    const endPoint = `/api/chat/room-list`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//쪽지 내용 가져오기 (관리자용) get
//userId : 유저 아이디 값
export const getchatingadmin = async (accessToken: string, refreshToken: string, userId: number): Promise<any> => {
    const endPoint = `/api/chat?userId=${userId}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//쪽지 내용 가져오기 (클라이언트용) get
export const getchatingclient = async (accessToken: string, refreshToken: string): Promise<any> => {
    const endPoint = `/api/chat`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequest(apiUrl, accessToken, refreshToken);
}

//pc 인터넷 연결 현황 가져오기 ipget
//XForwardedFor : ip값
export const getnetwork = async (accessToken: string, refreshToken: string,XForwardedFor : string): Promise<any> => {
    const endPoint = `/api/monitoring/network`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequestip(apiUrl, accessToken, refreshToken,XForwardedFor);
}

//특정 온도 및 용량 현황 가져오기 ipget
//pcSystemId : pc번호 XForwardedFor : ip값
export const gethardware = async (accessToken: string, refreshToken: string, pcSystemId: number,XForwardedFor : string): Promise<any> => {
    const endPoint = `/api/monitoring/hardware/heartbeat?pcSystemId=${pcSystemId}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequestip(apiUrl, accessToken, refreshToken,XForwardedFor);
}

//특정 pc 인터넷 연결 상태 확인하기 ipget
//pcSystemId : pc번호 XForwardedFor : ip값
export const getheartbeat = async (accessToken: string, refreshToken: string, pcSystemId: number,XForwardedFor : string): Promise<any> => {
    const endPoint = `/api/monitoring/network/heartbeat?pcSystemId=${pcSystemId}`;
    const apiUrl = API_BASE_URL + endPoint;
    return await sendGetRequestip(apiUrl, accessToken, refreshToken,XForwardedFor);
}

const sendGetRequest = async (apiUrl: string, accessToken: string, refreshToken: string): Promise<any> => {
    const response = await axios.get(apiUrl, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
            "Refresh-token": refreshToken,
        }
    });
    return response.data;
}

const sendGetRequestip = async (apiUrl: string, accessToken: string, refreshToken: string,XForwardedFor :string): Promise<any> => {
    const response = await axios.get(apiUrl, {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${accessToken}`,
            "Refresh-token": refreshToken,
            "X-Forwarded-For": XForwardedFor
        }
    });
    return response.data;
}