import React, { useEffect, useState, useRef } from "react";
import "../../styles/common/common.css";
import "../../styles/common/style.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/GroupSearchBar.tsx";
import Modal from "./AddModal.tsx";
import { getTempUsers, reviseMemo } from "../../apis/user/userApi.ts";

// 사용자 데이터 인터페이스 정의
interface User {
  id: number;
  email: string;
  memo: string | null;
  role: string;
  insertTs: string;
}

const Management: React.FC = () => {
  const title: string = "계정관리"; // 페이지 타이틀
  const breadcrumb: string[] = ["홈", "사용자관리", "계정관리"]; // 경로
  const [role, setRole] = useState<string>(""); // 사용자 역할
  const [userData, setUserData] = useState<User[]>([]); // 사용자 데이터
  const [userCount, setUserCount] = useState<number>(0); // 사용자 수
  const [userZero, setUserZero] = useState<number | null>(null); // 조회된 결과가 없는 경우
  const [editedMemos, setEditedMemos] = useState<Record<number, string>>({}); // 수정된 메모 저장
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // 모달 열기 상태
  const [pcGroup, setPcGroup] = useState<number | null>(null); // 선택된 PC 그룹
  const memoInputRef = useRef<HTMLInputElement | null>(null); // 메모 입력 필드에 대한 Ref

  // 컴포넌트가 마운트될 때 사용자 데이터를 가져옴
  useEffect(() => {
    const fetchData = async () => {
      const role: string | null = localStorage.getItem("role");
      setRole(role || "");
      if (role !== "ADMIN") {
        const pcGroupId: string | null = localStorage.getItem("group");
        const accessToken: string | null = localStorage.getItem("accessToken");
        const refreshToken: string | null = localStorage.getItem("refreshToken");
        if (pcGroupId && accessToken && refreshToken) {
          const response = await getTempUsers(
            accessToken,
            refreshToken,
            pcGroupId
          );
          setUserData(response.data);
          setUserCount(response.data.length);
          setUserZero(response.data.length === 0 ? 1 : null);
          const editedMemosData: Record<number, string> = {};
          response.data.forEach((item: User) => {
            editedMemosData[item.id] = item.memo || "";
          });
          setEditedMemos(editedMemosData);
        }
      } else {
        handleSearchBarChange(2);
      }
    };

    fetchData();
  }, []);

  // 검색바 선택이 변경될 때 호출
  const handleSearchBarChange = async (selectedOption: number | string) => {
    if (selectedOption !== "PC") {
      const accessToken: string | null = localStorage.getItem("accessToken");
      const refreshToken: string | null = localStorage.getItem("refreshToken");
      if (accessToken && refreshToken) {
        const response = await getTempUsers(
          accessToken,
          refreshToken,
          selectedOption as string
        );
        setUserData(response.data);
        setUserCount(response.data.length);
        setUserZero(response.data.length === 0 ? 1 : null);
        setPcGroup(selectedOption as number);
        response.data.forEach((item: User) => {
          setEditedMemos((prevMemos) => ({
            ...prevMemos,
            [item.id]: item.memo || "",
          }));
        });
      }
    }
  };

  // 날짜 포맷을 한국식으로 변환
  const formatKoreanDate = (dateString: string): string => {
    const insertDate: Date = new Date(dateString);
    const year: string = insertDate.getFullYear().toString();
    const month: string = ("0" + (insertDate.getMonth() + 1)).slice(-2);
    const day: string = ("0" + insertDate.getDate()).slice(-2);
    const hours: string = ("0" + insertDate.getHours()).slice(-2);
    const minutes: string = ("0" + insertDate.getMinutes()).slice(-2);
    const seconds: string = ("0" + insertDate.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // 메모를 수정할 때 호출
  const handleMemoEdit = async (id: number, memo: string) => {
    setEditedMemos((prevMemos) => ({
      ...prevMemos,
      [id]: memo,
    }));
    const accessToken: string | null = localStorage.getItem("accessToken");
    const refreshToken: string | null = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      await reviseMemo(accessToken, refreshToken, id, memo); // API를 통해 메모 업데이트
    }
  };

  // 계정 추가 버튼 클릭 시 모달 열기
  const handleAddAccount = () => {
    setIsModalOpen(true); // 모달 열기
  };

  // 모달 닫기
  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  // 새로운 사용자 정보 추가
  const updateinfo = (email: string) => {
    const newEntry: User = {
      id: userData.length > 0 ? userData[userData.length - 1].id + 1 : 1,
      email,
      memo: null,
      role: "전망대 직원",
      insertTs: new Date().toISOString(),
    };
    const newUserData: User[] = [...userData, newEntry];
    setUserData(newUserData);
  };

  return (
    <Body title={title} breadcrumb={breadcrumb}>
      {role === "ADMIN" && <SearchBar onSearch={handleSearchBarChange} />}
      <div className="card__container" style={{ backgroundColor: "white" }}>
        <div className="tableWrap">
          <div className="tableTop">
            <ul className="tableTop__total">
              <li>
                총 회원수 : <strong id="userCnt">{userCount}</strong>
              </li>
            </ul>
            <button className="logout-button" onClick={handleAddAccount}>
              계정 추가
            </button>{" "}
            {/* 계정 추가 버튼 */}
          </div>
          <div>
            <table className="tableSet tableSet--type1">
              <colgroup>
                <col style={{ width: "60px" }} />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>이메일</th>
                  <th>권한</th>
                  <th>메모</th>
                  <th>등록일</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                      <MemoField
                        user={user}
                        editedMemo={editedMemos[user.id] || ""}
                        onMemoEdit={handleMemoEdit}
                        memoInputRef={memoInputRef}
                      />
                    </td>
                    <td>{formatKoreanDate(user.insertTs)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {userZero === 1 && (
              <div className="menagelabel">조회된 결과가 없습니다.</div>
            )}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal closeModal={closeModal} updateinfo={updateinfo}>
          {/* 모달 내용 */}
          <div>
            {/* 계정 추가 폼 */}
            <h2>계정 추가</h2>
            {/* 폼 요소 추가 */}
          </div>
        </Modal>
      )}
    </Body>
  );
};

interface MemoFieldProps {
  user: User;
  editedMemo: string;
  onMemoEdit: (id: number, memo: string) => void;
  memoInputRef: React.RefObject<HTMLInputElement>;
}

// 메모 필드 컴포넌트
const MemoField: React.FC<MemoFieldProps> = ({
  user,
  editedMemo,
  onMemoEdit,
  memoInputRef,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const handleClick = () => {
    setEditMode(true); // 편집 모드 활성화
  };

  return editMode ? (
    <input
      ref={memoInputRef}
      type="text"
      value={editedMemo}
      onChange={(e) => onMemoEdit(user.id, e.target.value)}
      style={{ width: "100%", textAlign: "center" }} // 가운데 정렬
    />
  ) : (
    <span
      onClick={handleClick}
      style={{ cursor: "pointer", textAlign: "center", display: "block" }} // 가운데 정렬 및 줄 바꿈
    >
      {user.memo || "-"}
    </span>
  );
};

export default Management;
