import React, { useEffect, useState } from "react";
import Body from "../../components/Body.tsx";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import { getframe } from "../../apis/monitoring/moniteringApi.ts";
import Modal from "./AddScreenShot.tsx";

// PC 상태를 나타내는 인터페이스
interface PcStatus {
  pcLocation: string;
  pcKorName: string;
  ts: string;
  path: string;
}

// 프레임 데이터를 나타내는 인터페이스
interface FrameData {
  pcKorName: string;
  pcs: PcStatus[];
}

const AdminMonitoring: React.FC = () => {
  const title = "모니터링"; // 화면 제목
  const breadcrumb = ["홈", "모니터링", "관리자용 모니터링"]; // 경로 표시
  const [data, setData] = useState<FrameData[]>([]); // 프레임 데이터 상태
  const [, setError] = useState<string | null>(null); // 에러 상태
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // 모달 열림 상태
  const [modalData, setModalData] = useState<string | null>(null); // 모달 데이터
  const [size, setSize] = useState<number>(0); // 모달 크기 상태
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null); // 인터벌 ID
  const backgroundColor = `rgba(255, 255, 255, ${window.innerHeight * 0.004})`; // 배경 색상
  const [text, setText] = useState<string>(""); // 텍스트 상태
  const [timetext, setTimeText] = useState<string>(""); // 시간 텍스트 상태
  const [time, setTime] = useState<number>(50); // 시간 상태

  useEffect(() => {
    fetchImages(); // 컴포넌트가 마운트될 때 이미지 불러오기 함수 호출
  }, []);

  useEffect(() => {
    setData(data); // 데이터 상태 설정
    setText(text); // 텍스트 상태 설정
  }, [data, text]);

  const fetchImages = async () => {
    // 이미지를 불러오는 함수
    try {
      const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
      const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
      const ip = localStorage.getItem("ip"); // IP 가져오기
      const response = await getframe(accessToken!, refreshToken!, ip!); // 프레임 데이터 API 호출
      setData(response.data); // 불러온 데이터를 상태에 저장
    } catch (error) {
      setError(error.message); // 에러 메시지 상태에 저장
      console.error("Error loading images:", error); // 에러 발생 시 콘솔에 출력
    }
  };

  const openModal = (path: string) => {
    // 모달을 여는 함수
    setModalData(path); // 모달 데이터 설정
    setIsModalOpen(true); // 모달 열림 상태 설정
    setSize(0); // 모달 크기 초기화
    if (intervalId) clearInterval(intervalId); // 기존 인터벌 정지
  };

  const closeModal = () => {
    // 모달을 닫는 함수
    setIsModalOpen(false); // 모달 닫음 상태 설정
    setSize(0); // 모달 크기 초기화

    if (intervalId !== null) clearInterval(intervalId); // 기존 인터벌 정지

    const doc: Document & {
      webkitExitFullscreen?: () => Promise<void>;
      mozCancelFullScreen?: () => Promise<void>;
      msExitFullscreen?: () => Promise<void>;
    } = document;

    // 전체 화면 모드 해제
    if (doc.fullscreenElement) {
      if (doc.exitFullscreen) {
        doc.exitFullscreen().catch((err) => console.error(err));
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen().catch((err) => console.error(err));
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen().catch((err) => console.error(err));
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen().catch((err) => console.error(err));
      }
    }
  };

  const convertTextToDateTime = (text: string): Date => {
    // 텍스트를 Date 객체로 변환하는 함수
    const [date, time] = text.split("_");
    const year = "20" + date.substring(0, 2);
    const month = date.substring(2, 4);
    const day = date.substring(4, 6);
    const hour = time.substring(0, 2);
    const minute = time.substring(2, 4);
    return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
  };

  const updateAllImages = async () => {
    // 전체 업데이트 함수
    const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
    const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
    const ip = localStorage.getItem("ip"); // IP 가져오기
    const frame = await getframe(accessToken!, refreshToken!, ip!); // 프레임 데이터 API 호출
    setData(frame.data); // 불러온 데이터를 상태에 저장
    alert("전체PC의 이미지를 업데이트했습니다."); // 알림 표시
  };

  let currentPcIndex = 0; //현재 그룹 인덱스
  let currentFrameIndex = 0; //현재 pc인덱스 
  let frameData: FrameData[] = []; //프레임 데이터

  const showNextImage = async () => {
    // 다음 이미지를 보여주는 함수
    if (currentPcIndex === 0 && currentFrameIndex === 0) {
      try {
        const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
        const ip = localStorage.getItem("ip"); // IP 가져오기
        const frame = await getframe(accessToken!, refreshToken!, ip!); // 프레임 데이터 API 호출
        frameData = frame.data; // 불러온 데이터를 상태에 저장
      } catch (error) {
        alert("토큰이 만료되었습니다."); // 에러 발생 시 알림 표시
        return;
      }
    }

    let displayTime;
    if (data[currentPcIndex].pcs[currentFrameIndex].ts !== "") {
      displayTime = convertTextToDateTime(frameData[currentPcIndex].pcs[currentFrameIndex].ts).toLocaleString(); // 텍스트를 날짜 시간 형식으로 변환
    } else {
      displayTime = "";
    }

    setModalData(frameData[currentPcIndex].pcs[currentFrameIndex].path); // 모달 데이터 설정
    setText(`${frameData[currentPcIndex].pcs[currentFrameIndex].pcKorName} ${frameData[currentPcIndex].pcs[currentFrameIndex].pcLocation}`); // 텍스트 설정
    setTimeText(displayTime); // 시간 텍스트 설정

    if (currentPcIndex < frameData.length) {
      if (currentFrameIndex < frameData[currentPcIndex].pcs.length - 1) {
        currentFrameIndex++;
      } else {
        currentPcIndex++;
        currentFrameIndex = 0;
      }
    }

    if (currentPcIndex === frameData.length && currentFrameIndex === 0) {
      currentPcIndex = 0; // 데이터 순환 설정
    }

  };

  let countdown = 50;

  const startMonitoring = async () => {
    setSize(1); // 모달 크기 설정
    setIsModalOpen(true); // 모달 열림 상태 설정
    showNextImage(); // 모니터링 시작
    const intervalId = setInterval(updateCountdown, 100); // 0.1초마다 시간 카운트 함수 호출
    setIntervalId(intervalId); // 인터벌 ID 설정
    countdown = 50; // 시간 카운트 초기화

    // 전체 화면 모드 설정
    const element = document.documentElement as HTMLElement & {
      webkitRequestFullscreen?: () => Promise<void>;
      msRequestFullscreen?: () => Promise<void>;
    };

    if (element.requestFullscreen) {
      await element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      await element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      await element.msRequestFullscreen();
    }
  };

  const updateCountdown = () => {
    // 시간 카운트 함수
    if (countdown > 0) {
      countdown -= 1;
    } else {
      countdown = 50;
      showNextImage(); // 시간 카운트가 0이 되면 모니터링 시작
    }
    setTime(countdown); // 시간 상태 설정
  };

  return (
    <Body title={title} breadcrumb={breadcrumb}>
      <div
        className="card-con"
        style={{ backgroundColor: "white", borderRadius: "10px" }}
      >
        {data.map((image, index) => (
          <React.Fragment key={index}>
            <div className="card-row" style={{ marginTop: "1.5vh" }}>
              <div
                className={`network-card BLUE`}
                style={{
                  marginRight: "1vh",
                  marginLeft: "1vh",
                  width: "9vw",
                  height: "17vh",
                }}
              >
                <div className="card-content">
                  <span className="card-text" style={{ fontSize: "2vh", top: "1vh", left: "1vh" ,lineHeight: "2vh"}}>
                    {image.pcKorName}
                  </span>
                </div>
              </div>
              {image.pcs.map((status, statindex) => {
                const currentTime = new Date();
                const statusTime = convertTextToDateTime(status.ts);
                const isOldStatus = currentTime.getTime() - statusTime.getTime() > 20 * 60 * 1000; // 20분 이전인지 확인

                return status.ts !== "" ? (
                  <div
                    key={statindex}
                    className={`network-card ${isOldStatus ? "YELLOW" : "GREEN"}`}
                    style={{
                      marginRight: "1vh",
                      width: "14vw",
                      height: "17vh",
                      marginLeft: "3.5vh",
                      border: `1vh solid ${isOldStatus ? "#f44336" : "#b0ffa2"}`
                    }}
                  >
                    <div className="card-content">
                      <span
                        className="card-text"
                        style={{
                          top : "1.5vh",
                          left: "4.3vh",
                          fontSize: "0.67vw",
                          lineHeight: "2vh",
                          height: "2vh",
                          width : "8.2vw",
                          background: "rgba(255, 255, 255, 0.4)",
                        }}
                      >
                        &nbsp;&nbsp;{statusTime.toLocaleString()}
                        &nbsp;&nbsp;
                      </span>
                      <button onClick={() => openModal(status.path)}>
                        <img
                          src={status.path}
                          alt="이미지"
                          style={{ width: "13vw", height: "15.2vh", borderRadius: "3px" }}
                          onError={(e) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src =
                              "https://bora-asset-resource.s3.ap-northeast-2.amazonaws.com/common/logo/bora_logo_2_icon.png";
                          }}
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    key={statindex}
                    className={`network-card RED`}
                    style={{
                      marginRight: "1vh",
                      width: "35vh",
                      height: "20vh",
                      marginLeft: "3.5vh",
                    }}
                  >
                    <div className="card-content">
                      <img
                        src={
                          "https://bora-asset-resource.s3.ap-northeast-2.amazonaws.com/common/logo/bora_logo_2_icon.png"
                        }
                        alt="이미지"
                        style={{
                          width: "18vh",
                          borderRadius: "3px",
                          marginLeft: "10vh",
                        }}
                      />
                    </div>
                  </div>
                )
              })}
              {index === 0 && (
                <div className="network-group">
                  <button className="network-button" onClick={startMonitoring}>
                    모니터링
                  </button>
                  <button className="network-button" onClick={updateAllImages}>
                    전체 갱신
                  </button>
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
      {isModalOpen && modalData && (
        <Modal
          closeModal={closeModal}
          modalData={modalData}
          size={size}
          text={text}
          time={time}
          timetext={timetext}
        />
      )}
    </Body>
  );
};

export default AdminMonitoring;
