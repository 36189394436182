import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

export const API_BASE_URL = process.env.REACT_APP_API_ROOT;

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}
//특정 전망대에 특정 pc별 라벨 데이터 가져오기 get
//pcGroupId : 전망대 그룹 아이디 pcSystemId : pc번호 StartDate : 시작일 EndDate : 종료일
export const getLabelData = async (
  accessToken: string,
  refreshToken: string,
  pcGroupId: number | null,
  pcSystemId: string,
  StartDate: string,
  EndDate: string
): Promise<any> => {
  const endPoint = `/api/bora/label?pcGroupId=${pcGroupId}&pcSystemId=${pcSystemId}&startAt=${StartDate}&endAt=${EndDate}`;
  const apiUrl = `${API_BASE_URL}${endPoint}`;
  const data = await get(apiUrl, accessToken, refreshToken);
  return data;
};

//특정 전망대에 특정 pc에 대한 시간/횟수 데이터 가져오기 get
//pcSystemId : pc번호 StartDate : 시작일 EndDate : 종료일
export const getData = async (
  accessToken: string,
  refreshToken: string,
  pcSystemId: string,
  StartDate: string,
  EndDate: string
): Promise<any> => {
  const endPoint = `/api/bora/data?pcSystemId=${pcSystemId}&startAt=${StartDate}&endAt=${EndDate}`;
  const apiUrl = `${API_BASE_URL}${endPoint}`;
  const data = await get(apiUrl, accessToken, refreshToken);
  return data;
};

//특정 전망대에 설치된 모든 pc에 대한 데이터 가져오기(클라이언트 대시보드용) get
//amonthstring : 시작일 yesterdaystring : 종료일
export const allData = async (
  accessToken: string,
  refreshToken: string,
  amonthstring: string,
  yesterdaystring: string
): Promise<any> => {
  const endPoint = `/api/bora/data/all?startAt=${amonthstring}&endAt=${yesterdaystring}`;
  const apiUrl = `${API_BASE_URL}${endPoint}`;
  const data = await get(apiUrl, accessToken, refreshToken);
  return data;
};

//특정 전망대 별 pc 가져오기(라벨 관련 데이터 콤보박스용) get
export const pclabel = async (
  accessToken: string,
  refreshToken: string,
): Promise<any> => {
  const endPoint = `/api/bora/pc/label`;
  const apiUrl = `${API_BASE_URL}${endPoint}`;
  const data = await get(apiUrl, accessToken, refreshToken);
  return data;
};

//아쿠아 사진 가져오기 ipget
//page : 페이지 size : 가져올데이터갯수 sort : 오름차순/내림차순 선택 pcSystemId: pc번호 startAt : 시작일 endAt : 종료일 XForwardedFor : ip
export const aqua = async (
  accessToken: string,
  refreshToken: string,
  page: number,
  size: number,
  sort: string,
  pcSystemId: string,
  startAt: string,
  endAt: string,
  XForwardedFor : string
): Promise<any> => {
  const endPoint = `/api/monitoring/aqua/photo?page=${page}&size=${size}&sort=${sort}&pcSystemId=${pcSystemId}&startAt=${startAt}&endAt=${endAt}`;
  const apiUrl = `${API_BASE_URL}${endPoint}`;
  const data = await ipget(apiUrl, accessToken, refreshToken,XForwardedFor);
  return data;
};

//아쿠아 사진 다운로드 이력 저장하기 post
//XForwardedFor : ip boraAquaPhotoId : 사진id
export const aquaphoto = async (
  accessToken: string,
  refreshToken: string,
  boraAquaPhotoId: number,
  XForwardedFor: string
): Promise<any> => {
  const endPoint = `/api/monitoring/aqua/photo`;
  const apiUrl = `${API_BASE_URL}${endPoint}`;
  const data = await post(apiUrl, accessToken, refreshToken, XForwardedFor, boraAquaPhotoId);
  return data;
};



const get = async (apiUrl: string, accessToken: string, refreshToken: string): Promise<any> => {
  const response: AxiosResponse<any> = await axios.get(apiUrl, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${accessToken}`,
      "Refresh-token": refreshToken,
    },
  });
  return response.data;
};

const post = async (apiUrl: string, accessToken: string, refreshToken: string, XForwardedFor: string, boraAquaPhotoId: number): Promise<any> => {
  const response = await axios.post(apiUrl, boraAquaPhotoId, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${accessToken}`,
      'Refresh-token': refreshToken,
      "X-Forwarded-For": XForwardedFor
    },
  });
  return response.data;
};


const ipget = async (apiUrl: string, accessToken: string, refreshToken: string, XForwardedFor: string): Promise<any> => {
  const response: AxiosResponse<any> = await axios.get(apiUrl, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${accessToken}`,
      "Refresh-token": refreshToken,
      "X-Forwarded-For" : XForwardedFor
    },
  });
  return response.data;
};