import React, { useEffect, useState } from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import "../../components/SideBar.tsx";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import axios from "axios";
import ReactPlayer from "react-player";
import streaming from "../../assets/images/streaming.png";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar.tsx";
import { getStreamings } from "../../apis/monitoring/moniteringApi.ts";
import { allData } from "../../apis/data/dataApi.ts";
import { controlpc, boragroup } from "../../apis/version/versionApi.ts";
import { getpcinfo, getpcinfoclient } from "../../apis/dashboard/dashboardApi.ts";

// PC 데이터 인터페이스
interface PCData {
  [key: string]: any;
}

// 모달 에러 인터페이스
interface ModalError {
  id: string;
  logcode: string;
  logdata: string;
  writetime: string;
}

// 차트 컴포넌트 정의
const ChartComponent: React.FC<{
  title: string; // 차트 제목
  data: PCData[]; // 차트 데이터
  dataList: string[]; // 데이터 리스트
  colorArray: string[]; // 색상 배열
  useCustomLabel?: boolean; // 커스텀 라벨 사용 여부
}> = ({ title, data, dataList, colorArray, useCustomLabel = false }) => {
  // 분을 시간과 분으로 변환하는 함수
  const convertMinutesToHoursAndMinutes = (minutes: number) => {
    if (minutes < 60) {
      return `${minutes}분`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}시간 ${remainingMinutes}분`;
    }
  };

  // 커스텀 라벨 렌더링 함수
  const renderCustomLabel = (props: any) => {
    const { x, y, width, value } = props;
    return (
      <text
        x={x + width / 2}
        y={y - 10}
        fill="#666"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={8}
      >
        {convertMinutesToHoursAndMinutes(value)}
      </text>
    );
  };

  // 툴팁 포맷터 함수
  const customTooltipFormatter = (value: any) => {
    return convertMinutesToHoursAndMinutes(value);
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data} margin={{ top: 70, right: 30, left: 20, bottom: 5 }}>
        <text
          x="50%"
          y="5%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="20"
          fill="#333"
        >
          {title}
        </text>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={useCustomLabel ? customTooltipFormatter : undefined} />
        <Legend wrapperStyle={{ marginLeft: "30px" }} />

        {dataList.map((item, index) => (
          <Bar key={index} dataKey={item} fill={colorArray[index % colorArray.length]}>
            {useCustomLabel && <LabelList dataKey={item} content={renderCustomLabel} />}
            {!useCustomLabel && <LabelList dataKey={item} position="top" />}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

// 대시보드 컴포넌트 정의
const Dashboard: React.FC = () => {
  const title = "대시보드"; // 제목
  const breadcrumb = ["대시보드"]; // 경로
  const [modeCntData, setmodeCntData] = useState<PCData[]>([]); // 모드별 진입횟수 데이터
  const [featureData, setfeatureData] = useState<PCData[]>([]); // 기능별 사용횟수 데이터
  const [languageData, setlanguageData] = useState<PCData[]>([]); // 언어별 사용횟수 데이터
  const [modeUsageData, setmodeUsageData] = useState<PCData[]>([]); // 모드별 사용시간 데이터
  const [modeList, setModeList] = useState<string[]>([]); // 모드 리스트
  const [featureList, setfeatureList] = useState<string[]>([]); // 기능 리스트
  const [languageList, setlanguageList] = useState<string[]>([]); // 언어 리스트
  const [modeUsageList, setmodeUsageList] = useState<string[]>([]); // 모드 사용시간 리스트
  const [url, setUrl] = useState<any>(null); // 스트리밍 URL
  const [alldata, setalldata] = useState<any>(""); // 모든 데이터
  const [role, setrole] = useState<string | null>(""); // 역할
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // 모달 오픈 여부
  const [modalerror, setmodalerror] = useState<ModalError[] | null>(null); // 모달 에러
  const colorArray = [
    "#1f78b4",
    "#33a02c",
    "#e31a1c",
    "#ff7f00",
    "#6a3d9a",
    "#a6cee3",
    "#b2df8a",
    "#fb9a99",
  ]; // 색상 배열

  // 데이터 처리 함수
  const processData = (data: PCData[], property: string): PCData[] => {
    return data.map((pc, index) => {
      const pcData: PCData = {
        name: `${index + 1}번 PC`,
      };

      Object.keys(data[0][property]).forEach((key) => {
        pcData[key] = pc[property][key];
      });

      return pcData;
    });
  };

  // URL API 호출 함수
  const urlapi = async () => {
    const pcGroupId = localStorage.getItem("group");
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await getStreamings(accessToken!, refreshToken!, pcGroupId);

    if (response.result === true) {
      setUrl(response.data);
    } else {
      alert("데이터 불러오기 실패");
    }
  };

  // 날짜 포맷팅 함수
  const formatDate = (dateTimeString: string): string => {
    const date = new Date(dateTimeString);
    return date.toLocaleString(); // 기본 로케일에 맞게 현재 시간대의 시간 형식으로 변환
  };

  // 모달 닫기 함수
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 그래프 데이터 설정 함수
  const graph = async () => {
    const currentDate = new Date();
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const yesterdaystring = yesterday.toISOString().split("T")[0];
    const amonth = new Date(yesterday);
    amonth.setMonth(yesterday.getMonth() - 1);
    const amonthstring = amonth.toISOString().split("T")[0];

    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await allData(accessToken!, refreshToken!, amonthstring, yesterdaystring);
    if (response.result === true) {
      setModeList(Object.keys(response.data.pc[0].mode));
      setfeatureList(Object.keys(response.data.pc[0].feature));
      setlanguageList(Object.keys(response.data.pc[0].language));
      setmodeUsageList(Object.keys(response.data.pc[0].modeUsage));
      const mode = processData(response.data.pc, "mode");
      setmodeCntData(mode);
      const language = processData(response.data.pc, "language");
      setlanguageData(language);
      const feature = processData(response.data.pc, "feature");
      setfeatureData(feature);
      const modeUsage = processData(response.data.pc, "modeUsage");
      setmodeUsageData(modeUsage);
    }
  };

  // 정보 설정 함수
  const info = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    const role = localStorage.getItem("role");
    if (role === "ADMIN") {
      const response = await getpcinfo(accessToken!, refreshToken!);
      setalldata(response.data);
      localStorage.setItem("data", JSON.stringify(response.data));
    } else {
      const Group: string | null = localStorage.getItem("group");
      if (Group) {
        const response = await getpcinfoclient(accessToken!, refreshToken!, Group);
        setalldata(response.data);
        localStorage.setItem("data", JSON.stringify(response.data));
      }
    }
  };

  // 컴포넌트가 마운트될 때 실행되는 이펙트
  useEffect(() => {
    const role = localStorage.getItem("role");
    setrole(role);
    info();
    if (role !== "ADMIN") {
      graph();
      urlapi();
    }
  }, []);

  return (
    <>
      <Body title={title} breadcrumb={breadcrumb}>
        {isModalOpen && (
          <div className="modal-background" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <div className="modal-scroll">
                {modalerror !== null &&
                  modalerror.map((elem, index) => (
                    <p key={index}>
                      id: {elem.id} logcode: {elem.logcode} logdata: {elem.logdata} writetime: {formatDate(elem.writetime)}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        )}
        <div className="box-container" style={{ backgroundColor: "white", borderRadius: "10px" }}>
          <div className="box-row">
            <div className="box">
              <ChartComponent
                title="모드별 진입횟수"
                data={modeCntData}
                dataList={modeList}
                colorArray={colorArray}
              />
            </div>
            <span className="buttonSpacer"></span>
            <div className="box">
              <ChartComponent
                title="기능별 사용횟수"
                data={featureData}
                dataList={featureList}
                colorArray={colorArray}
              />
            </div>
          </div>
          <div className="box-row">
            <div className="box">
              <ChartComponent
                title="모드별 사용시간"
                data={modeUsageData}
                dataList={modeUsageList}
                colorArray={colorArray}
                useCustomLabel={true} // 커스텀 라벨 사용
              />
            </div>
            <span className="buttonSpacer"></span>
            <div className="box">
              <ChartComponent
                title="언어별 사용횟수"
                data={languageData}
                dataList={languageList}
                colorArray={colorArray}
              />
            </div>
          </div>
          <div className="box-row">
            <div className="box2">
              <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                1번 PC
              </div>
              {url !== null ? (
                url.StreamingCnt > 0 ? (
                  <ReactPlayer
                    height="90%"
                    url={url.Streaming[0].path}
                    controls
                    width="100%"
                    playing // 자동 재생
                  />
                ) : (
                  <img src={streaming} alt="" style={{ marginLeft: "90px", marginTop: "25px", width: "40%" }} />
                )
              ) : (
                <img src={streaming} alt="" style={{ marginLeft: "90px", marginTop: "25px", width: "40%" }} />
              )}
            </div>
            <span className="buttonSpacer" style={{ marginRight: "80px" }}></span>
            <div className="box2">
              <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                2번 PC
              </div>
              {url !== null ? (
                url.StreamingCnt > 1 ? (
                  <ReactPlayer
                    height="90%"
                    url={url.Streaming[1].path}
                    controls
                    width="100%"
                    playing // 자동 재생
                  />
                ) : (
                  <img src={streaming} alt="" style={{ marginLeft: "90px", marginTop: "25px", width: "40%" }} />
                )
              ) : (
                <img src={streaming} alt="" style={{ marginLeft: "90px", marginTop: "25px", width: "40%" }} />
              )}
            </div>
            <span className="buttonSpacer" style={{ marginLeft: "65px" }}></span>
            <div className="box2">
              <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                3번 PC
              </div>
              {url !== null ? (
                url.StreamingCnt > 2 ? (
                  <ReactPlayer
                    height="100%"
                    url={url.Streaming[2].path}
                    controls
                    width="100%"
                    playing // 자동 재생
                  />
                ) : (
                  <img src={streaming} alt="" style={{ marginLeft: "90px", marginTop: "25px", width: "40%" }} />
                )
              ) : (
                <img src={streaming} alt="" style={{ marginLeft: "90px", marginTop: "25px", width: "40%" }} />
              )}
            </div>
            <span className="buttonSpacer" style={{ marginRight: "80px" }}></span>
            <div className="box2">
              <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                4번 PC
              </div>
              {url !== null ? (
                url.StreamingCnt > 3 ? (
                  <ReactPlayer
                    height="100%"
                    url={url.Streaming[3].path}
                    controls
                    width="100%"
                    playing // 자동 재생
                  />
                ) : (
                  <img src={streaming} alt="" style={{ marginLeft: "90px", marginTop: "25px", width: "40%" }} />
                )
              ) : (
                <img src={streaming} alt="" style={{ marginLeft: "90px", marginTop: "25px", width: "40%" }} />
              )}
            </div>
          </div>
        </div>
      </Body>
    </>
  );
};

export default Dashboard;
