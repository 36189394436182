import React from "react";
import "../../styles/common/style.css";
import "../../styles/common/common.css";
import Body from "../../components/Body.tsx";
import SearchBar from "../../components/SearchBar.tsx";
import ready from "../../assets/images/ready.png";

// 방명록 관리
const VisitBook = () => {
  const title = "방명록 관리";
  const breadcrumb = ["홈", "모니터링", "방명록 관리"];
  return (
    <>
      <Body title={title} breadcrumb={breadcrumb}>
        <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
          <img src={ready} style={{ marginLeft: "25%", width: "50%" }} />
        </div>
      </Body>
    </>
  );
};
export default VisitBook;
