import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import "../styles/common/style.css";
import "../styles/common/common.css";
import "./dashboard/Dashboard.tsx";
import "./Signup.tsx";
import { signin } from "../apis/user/userApi.ts";
import awesomelogo from "../assets/images/awesomepia/logo.png";

const Login: React.FC = () => {
  // 로그인 ID, 비밀번호, IP 주소에 대한 상태 변수
  const [loginId, setLoginId] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  // 로그인 ID, 비밀번호 입력란 및 로그인 버튼에 대한 참조
  const loginIdRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const loginButtonRef = useRef<HTMLButtonElement>(null);

  // 네비게이션 훅
  const navigate = useNavigate();

  // 사용자의 IP 주소를 가져오는 함수
  const fetchIp = async () => {
    try {
      const response = await axios.get('https://jsonip.com/');
      localStorage.setItem("ip", response.data.ip);
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  // 컴포넌트가 마운트될 때 IP 주소를 가져옴
  useEffect(() => {
    fetchIp();
  }, []);

  // 로그인 처리 함수
  const login = async () => {
    const response = await signin(loginId, password);
    if (response.data.result === true) {
      // 로컬 스토리지에 토큰 및 사용자 정보를 저장
      const authorization: string = response.headers["access-token"];
      const refresh: string = response.headers["refresh-token"];
      localStorage.setItem("accessToken", authorization);
      localStorage.setItem("refreshToken", refresh);
      localStorage.setItem("role", response.data.data.role);
      localStorage.setItem("group", response.data.data.clientRes.id);
      localStorage.setItem("main", "1");
      localStorage.setItem("depth", "0");
      localStorage.setItem("depth2", "0");
      localStorage.setItem("id", response.data.data.clientRes.id);
      localStorage.setItem("nick", response.data.data.nickname);
      // 대시보드 페이지로 이동
      navigate("/Dashboard");
    } else {
      alert("비밀번호가 맞지 않습니다."); // 비밀번호가 틀릴 경우 알림
    }
  };

  return (
    <div className="login">
      <div className="loginWrap" style={{ height: 450 }}>
        <div className="header">
          <div className="logo">
            <a href="">
              <img
                src={awesomelogo}
                alt="회사로고"
                style={{ width: "150px" }}
              />
            </a>
          </div>
        </div>
        <div className="loginWrap__ipt">
          <div className="txtBox">
            <p className="tit">보라 백오피스 로그인</p>
          </div>
          <form
            id="saveForm"
            className="form-horizontal"
            method="post"
            autoComplete="off"
          >
            <div className="iptBox">
              <div className="row">
                <p className="tit">아이디</p>
                <input
                  type="text"
                  id="loginId"
                  name="loginId"
                  maxLength={30}
                  placeholder="로그인 ID"
                  value={loginId}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setLoginId(e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      if (e.shiftKey) {
                        e.preventDefault();
                        loginIdRef.current?.focus();
                      } else {
                        e.preventDefault();
                        passwordRef.current?.focus();
                      }
                    } else if (e.key === "Enter") {
                      e.preventDefault();
                      passwordRef.current?.focus();
                    }
                  }}
                  ref={loginIdRef}
                />
              </div>
              <div className="row">
                <p className="tit">
                  비밀번호
                  <a
                    href="#"
                    onClick={() => alert("개발담당자에게 문의주세요")}
                  >
                    비밀번호 찾기
                  </a>
                </p>
                <input
                  type="password"
                  id="password"
                  name="password"
                  maxLength={20}
                  placeholder="비밀번호를 입력하세요."
                  value={password}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Tab" && e.shiftKey) {
                      e.preventDefault();
                      loginIdRef.current?.focus();
                    } else if (e.key === "Enter") {
                      e.preventDefault();
                      login();
                    }
                  }}
                  ref={passwordRef}
                />
              </div>
              <div className="row">
                <button
                  type="button"
                  className="loginBtn"
                  onClick={login}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      login();
                    }
                  }}
                  ref={loginButtonRef}
                >
                  로그인
                </button>
                <span className="buttonSpacer"></span>
                {/* 회원가입 버튼이 필요할 경우 다음 줄을 수정하고 주석 해제 */}
                {/* <button type="button" className="loginBtn" onClick={goToSignUp}>
                  회원가입
                </button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="loginBg"></div>
    </div>
  );
};

export default Login;
