/**
 * 기본 로케일에 맞게 현재 시간대의 시간 형식으로 변환
 * @param dateTimeString - 날짜 및 시간 문자열
 * @returns 포맷된 날짜 및 시간 문자열
 */
export const formatDate = (dateTimeString: string): string => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
