import React, { useEffect, useState } from "react";
import "../styles/common/common.css";
import "../styles/common/style.css";

interface DataItem {
  pcLocation: string;
  pcSystemId: string;
  pcKorName: string;
}

interface Props {
  onSearch: (
    startDate: string,
    endDate: string,
    selectedOption: string
  ) => void;
  calendar?: any;
}

const SearchBar: React.FC<Props> = ({ onSearch, calendar }) => {
  const [dataArray, setDataArray] = useState<DataItem[]>([]);
  const [StartDate, setStartDate] = useState<string>("");
  const [EndDate, setEndDate] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");

  const getDataFromLocal = () => {
    const storedData = localStorage.getItem("data");
    if (storedData) {
      const parsedData: DataItem[] = JSON.parse(storedData);
      setDataArray(parsedData);
      setSelectedOption(parsedData[0].pcSystemId);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const yesterdaystring = yesterday.toISOString().split("T")[0];
    const amonth = new Date(yesterday);
    amonth.setMonth(yesterday.getMonth() - 1);
    const amonthstring = amonth.toISOString().split("T")[0];
    setStartDate(amonthstring);
    setEndDate(yesterdaystring);
    getDataFromLocal();
  }, []);

  const calendarclick = (month: number) => {
    const currentDate = new Date();
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const yesterdaystring = yesterday.toISOString().split("T")[0];
    const amonth = new Date(yesterday);
    amonth.setMonth(yesterday.getMonth() - month);
    const amonthstring = amonth.toISOString().split("T")[0];
    setStartDate(amonthstring);
    setEndDate(yesterdaystring);
  };

  const handleButtonClick = () => {
    if (selectedOption !== "PC") {
      onSearch(StartDate, EndDate, selectedOption);
    }
  };

  const StartDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const EndDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const placeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <form
      id="searchForm"
      className="form-horizontal"
      onSubmit={(e) => e.preventDefault()}
      autoComplete="off"
    >
      <div className="searchWrap">
        <div className="lt">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th>조회 기간</th>
                <td>
                  <div className="datepick">
                    <input
                      type="date"
                      className="iptInput"
                      placeholder="placeholder"
                      value={StartDate ? StartDate : ""}
                      onChange={StartDayChange}
                    />
                    <p>~</p>
                    <input
                      type="date"
                      className="iptInput"
                      placeholder="placeholder"
                      value={EndDate ? EndDate : ""}
                      onChange={EndDayChange}
                    />
                    <div style={{ marginLeft: "auto", marginRight: "-1vh" }}>
                      <button
                        className="day-button"
                        onClick={() => calendarclick(1)}
                        style={{ marginRight: "10px",width :"55px"}}
                      >
                        1개월
                      </button>
                      <button
                        className="day-button"
                        onClick={() => calendarclick(3)}
                        style={{ marginRight: "10px",width :"55px" }}
                      >
                        3개월
                      </button>
                      <button
                        className="day-button"
                        onClick={() => calendarclick(6)}
                         style={{ marginRight: "10px",width :"55px" }}
                      >
                        6개월
                      </button>
                    </div>
                  </div>
                </td>
                <th>전망대 PC</th>
                <td>
                  <select
                    className="iptSelect"
                    value={selectedOption}
                    onChange={placeChange}
                  >
                    {dataArray.map((item, index) => (
                      <option key={index} value={item.pcSystemId}>
                        {item.pcKorName} {item.pcLocation}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <button
            className="day-button"
            onClick={handleButtonClick}
            style={{ marginTop: "8px", width: "54px" }}
          >
            검색
          </button>
        </div>
      </div>
    </form>
  );
};

export default SearchBar;
