import React, { useState, useEffect } from "react";
import Body from "../../components/Body.tsx";
import { upload, controlversion, controlpc, controlstatus, controlput } from "../../apis/version/versionApi.ts";
import Loader from "../../components/Loader.tsx";
import ControlSearchBar from "../../components/ControlSearchBar.tsx";
import Pagination from '@mui/material/Pagination';

export const API_BASE_URL = process.env.REACT_APP_WSS_ROOT as string;

// 데이터 인터페이스 정의
interface Data {
    pcKorName: string;      // PC 한글 이름
    verId: string;          // 버전 ID
    pcLocation: string;     // PC 위치
    pcSystemId: number;     // PC 시스템 ID
    id: number;             // 데이터 ID
    fileName: string;       // 파일 이름
    comment: string;        // 코멘트
    path: string;           // 파일 경로
}

const ControlServer: React.FC = () => {
    // 상태 변수 정의
    const [data, setData] = useState<Data[]>([]);        // 버전 데이터 배열
    const [loading, setLoading] = useState<boolean>(false);  // 로딩 상태
    const [userCount, setUserCount] = useState<number>(0);   // 사용자 수
    const [pageInfo, setPageInfo] = useState<any>(null);      // 페이지 정보
    const [currentPage, setCurrentPage] = useState(1);        // 현재 페이지
    const [Pagesize, setPagesize] = useState(0);              // 페이지 크기
    const [selectpage, setselectpage] = useState(0);          // 선택한 페이지
    const [datastatus, setdatastatus] = useState<Data[]>([]);  // 상태 데이터 배열
    const [versiondata, setversiondata] = useState<Data[]>([]);  // 버전 데이터 배열
    const [versionInputs, setVersionInputs] = useState<{ [key: number]: string }>({});  // 버전 입력 값
    let page = 0;   // 페이지 번호

    // 초기 데이터 로드 효과 훅
    useEffect(() => {
        setting();    // 데이터 설정 함수 호출
        pageselect();  // 페이지 선택 함수 호출
    }, []);

    // API에서 데이터 가져와서 상태 설정
    const setting = async () => {
        const accessToken = localStorage.getItem("accessToken");   // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기

        // PC 데이터 가져오기
        const response = await controlpc(accessToken!, refreshToken!);
        localStorage.setItem("controldata", JSON.stringify(response.data));
        
        // 상태 데이터 가져오기
        const response2 = await controlstatus(accessToken!, refreshToken!);
        setdatastatus(response2.data);

        // 버전 데이터 가져오기
        const response3 = await controlversion(accessToken!, refreshToken!, page, 40, "ASC");
        setPagesize(response3.data.page.length);
        setversiondata(response3.data.page);
    }

    // 검색 바의 변경 처리
    const handleSearchBarChange = async (
        SelectedOption: string | string[] | undefined,
        file: File,
        filename: string,
        comment: string
    ) => {
        setLoading(true);   // 로딩 상태 설정
        const accessToken = localStorage.getItem("accessToken");   // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
        try {
            const response = await upload(accessToken!, refreshToken!, file, filename, comment, SelectedOption);
            setLoading(false);  // 로딩 상태 해제
            pageselect();       // 페이지 선택 함수 호출
        } catch {
            setLoading(false);  // 로딩 상태 해제
        }
    };

    // 페이지 변경 처리 (페이징)
    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);   // 현재 페이지 설정
        page = value - 1;        // 페이지 번호 설정
        pageselect();            // 페이지 선택 함수 호출
    };

    // 현재 페이지에 따라 데이터 가져오기
    const pageselect = async () => {
        const accessToken = localStorage.getItem("accessToken");   // 액세스 토큰 가져오기
        const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
        const ip = localStorage.getItem("ip");                      // IP 가져오기
        if (accessToken && refreshToken && ip) {
            try {
                // 버전 데이터 가져오기
                const response = await controlversion(accessToken, refreshToken, page, 20, "ASC");
                setUserCount(response.data.pageInfo.totalItems);   // 사용자 수 설정
                setPageInfo(response.data.pageInfo);               // 페이지 정보 설정
                setData(response.data.page);                       // 데이터 설정
            } catch (error) {
                alert("접근할 수 없는 ip입니다.");
                window.history.back(); // 이전 화면으로 이동
            }
        }
    };

    // 버전 업데이트를 위한 입력 값 변경 처리
    const handleInputChange = (id: number, value: string) => {
        setVersionInputs(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    // 버전 업데이트 처리
    const handleUpdateVersion = async (id: number, pcSystemId: number) => {
        const confirmUpdate = window.confirm("버전을 수정하시겠습니까?"); // 수정 확인 메시지
        if (!confirmUpdate) {
            return;  // 수정 취소
        }

        const newVersion = Number(versionInputs[id]);  // 새로운 버전 번호
        if (newVersion) {
            const accessToken = localStorage.getItem("accessToken");   // 액세스 토큰 가져오기
            const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
            const response = await controlput(accessToken!, refreshToken!, pcSystemId, newVersion); // 버전 업데이트 API 호출
            setVersionInputs(prevState => ({
                ...prevState,
                [id]: ''
            }));
            if (response.message !== "엔티티가 존재하지 않습니다.") {
                const response2 = await controlstatus(accessToken!, refreshToken!); // 상태 데이터 다시 가져오기
                setdatastatus(response2.data);
            } else {
                alert("버전이 존재하지 않습니다.");
            }
        } else {
            alert("수정할 버전값을 넣어주세요.");
        }
    };

    return (
        <Body title="모니터링" breadcrumb={["홈", "서버 관리", "컨트롤서버 관리"]}>
            {selectpage === 0 ? (
                <>
                    {/* 버전 관리 탭으로 이동 버튼 */}
                    <button
                        className="logout-button" style={{ marginLeft: "0vw", marginTop: "-1vh", marginBottom: "1vh", width: "8.5vw", justifyContent: "center", }}
                        onClick={() => { setselectpage(1) }}
                    >
                        버전 관리탭으로 이동
                    </button>
                    <div className="search-bar-container">
                        {loading && (
                            <div className="search-bar-overlay">
                                <Loader />
                            </div>
                        )}
                        <ControlSearchBar onSearch={handleSearchBarChange} />
                    </div>
                    <div
                        className="box-container"
                        style={{ backgroundColor: "white", borderRadius: "10px" }}
                    >
                        <div className="tableWrap">
                            <div className="tableTop">
                                <ul className="tableTop__total">
                                    <li>
                                        총 버전수 : <strong id="userCnt">{userCount}</strong>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <table className="tableSet tableSet--type1">
                                    <colgroup>
                                        <col style={{ width: "5vw" }} />
                                        <col style={{ width: "30vw" }} />
                                        <col style={{ width: "10vw" }} />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>번호</th>
                                            <th>파일이름</th>
                                            <th>코멘트</th>
                                            <th>파일 경로</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.fileName}</td>
                                                <td>{item.comment}</td>
                                                <td>{item.path}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {userCount === 0 && (
                                    <div className="menagelabel">조회된 결과가 없습니다.</div>
                                )}
                            </div>
                        </div>
                        {pageInfo && (
                            <div className="pagination-container">
                                <Pagination
                                    count={pageInfo.totalRanges}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    color="primary"
                                />
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {/* 버전 업로드 탭으로 이동 버튼 */}
                    <button
                        className="logout-button" style={{ marginLeft: "0vw", marginTop: "-1vh", marginBottom: "1vh", width: "8.5vw", justifyContent: "center", }}
                        onClick={() => { setselectpage(0) }}
                    >
                        버전 업로드탭으로 이동
                    </button>
                    <div style={{ display: "flex" }}>
                        <div
                            className="box-container"
                            style={{ backgroundColor: "white", borderRadius: "10px", width: "50%" }}
                        >
                            <div className="tableWrap">
                                <div className="tableTop">
                                    <ul className="tableTop__total">
                                        <li>
                                            버전 리스트 : <strong id="userCnt">{datastatus.length}</strong>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <table className="tableSet tableSet--type1">
                                        <colgroup>
                                            <col style={{ width: "15vw" }} />
                                            <col style={{ width: "5vw" }} />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>PC</th>
                                                <th>버전</th>
                                                <th>버전 수정</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {datastatus.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.pcSystemId}번PC {item.pcKorName} {item.pcLocation}</td>
                                                    <td>{item.verId}</td>
                                                    <td>
                                                        <div style={{ display: "flex" }}>
                                                            <input
                                                                style={{ width: "13vw" }}
                                                                type="text"
                                                                placeholder="수정할 버전 번호를 적어주세요."
                                                                value={versionInputs[item.id] || ''}
                                                                onChange={(e) => handleInputChange(item.id, e.target.value)}
                                                            />
                                                            <button className="logout-button" onClick={() => handleUpdateVersion(item.id, item.pcSystemId)}>
                                                                버전 변경
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {userCount === 0 && (
                                        <div className="menagelabel">조회된 결과가 없습니다.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className="box-container"
                            style={{ backgroundColor: "white", borderRadius: "10px", width: "50%", marginLeft: "2%" }}
                        >
                            <div className="tableWrap">
                                <div className="tableTop">
                                    <ul className="tableTop__total">
                                        <li>
                                            총 버전수 : <strong id="userCnt">{Pagesize}</strong>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <table className="tableSet tableSet--type1">
                                        <colgroup>
                                            <col style={{ width: "5vw" }} />
                                            <col style={{ width: "15vw" }} />
                                            <col style={{ width: "10vw" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>번호</th>
                                                <th>파일이름</th>
                                                <th>코멘트</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {versiondata.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.id}</td>
                                                    <td>{item.fileName}</td>
                                                    <td>{item.comment}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {userCount === 0 && (
                                        <div className="menagelabel">조회된 결과가 없습니다.</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Body>
    );
};

export default ControlServer;
