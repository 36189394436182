import React, { useEffect, useState, useRef } from "react";
import Body from "../../components/Body.tsx";
import {
  getchatingclient,
  getchatingadmin,
  getroomlist,
} from "../../apis/monitoring/moniteringApi.ts";

// WebSocket 서버 주소를 환경 변수에서 가져옵니다
export const API_BASE_URL = process.env.REACT_APP_WSS_ROOT as string;

const Chating: React.FC = () => {
  // 입력된 메시지 상태 관리
  const [inputMessage, setInputMessage] = useState<string>("");
  // 채팅 메시지 목록 상태 관리
  const [messages, setMessages] = useState<any[]>([]);
  // 채팅방 목록 상태 관리
  const [roomList, setRoomList] = useState<any[]>([]);
  // 선택된 채팅방 ID 상태 관리
  const [selectroom, setSelectRoom] = useState<number>(0);
  // 사용자 역할 상태 관리
  const [role, setRole] = useState<string | null>(null);
  // 선택된 채팅방 인덱스 상태 관리
  const [selectedRoomIndex, setSelectedRoomIndex] = useState<number | null>(
    null
  );
  // 사용자 콘텐츠 상태 관리
  const [content, setContent] = useState<any[]>([]);
  // WebSocket 객체 useRef로 관리
  const ws = useRef<WebSocket | null>(null);
  // 채팅 스크롤 제어를 위한 ref
  const bottomOfChatRef = useRef<HTMLDivElement | null>(null);
  // WebSocket 연결 객체를 useRef로 관리
  const webSocket = useRef<WebSocket>(new WebSocket(API_BASE_URL + "/ws/chat"));
  // 로컬 스토리지에서 접근 토큰 가져오기
  const accessToken = localStorage.getItem("accessToken");
  // 로컬 스토리지에서 리프레시 토큰 가져오기
  const refreshToken = localStorage.getItem("refreshToken");

  // 역할에 따라 채팅방 목록 읽기
  useEffect(() => {
    const role = localStorage.getItem("role");
    setRole(role);
    if (role === "ADMIN") {
      readroom();
    } else if (role === "CLIENT_MANAGER") {
      getchating();
    }

    ws.current = webSocket.current;
    webSocket.current.onopen = () => {
      // WebSocket 연결이 열리면 사용자 정보 전송
      const param = {
        actionType: "CONNECT",
        param: {
          accessToken: `Bearer ${accessToken}`,
          refreshToken: `${refreshToken}`,
        },
      };
      webSocket.current?.send(JSON.stringify(param));
    };

    webSocket.current.onmessage = (event) => {
      // WebSocket 메시지 수신 처리
      const receivedMessage = JSON.parse(event.data);
      if (receivedMessage.content !== "") {
        // 받은 메시지 처리
        if (role !== "ADMIN" && receivedMessage.userId === 1) {
          // 클라이언트 관리자일 때
          const messageTime = new Date(receivedMessage.insertTs);
          let time = "";
          let hour = "";
          if (messageTime.getHours() < 12) {
            time = "오전";
            hour = messageTime.getHours() + ":" + messageTime.getMinutes();
          } else {
            time = "오후";
            hour = messageTime.getHours() - 12 + ":" + messageTime.getMinutes();
          }
          const formattedMessage = {
            sender: true,
            text: receivedMessage.content,
            time: time + " " + hour,
            day: new Date(receivedMessage.insertTs),
          };
          setMessages((prevMessages) => [...prevMessages, formattedMessage]);
        } else if (role === "ADMIN" && receivedMessage.role !== "ADMIN") {
          // 어드민일 때
          const messageTime = new Date(receivedMessage.insertTs);
          let time = "";
          let hour = "";
          if (messageTime.getHours() < 12) {
            if (messageTime.getMinutes() < 9) {
              time = "오전";
              hour = messageTime.getHours() + ":0" + messageTime.getMinutes();
            } else {
              time = "오전";
              hour = messageTime.getHours() + ":" + messageTime.getMinutes();
            }
          } else {
            if (messageTime.getMinutes() < 9) {
              time = "오전";
              hour =
                messageTime.getHours() - 12 + ":0" + messageTime.getMinutes();
            } else {
              time = "오전";
              hour =
                messageTime.getHours() - 12 + ":" + messageTime.getMinutes();
            }
          }
          const formattedMessage = {
            sender: true,
            text: receivedMessage.content,
            time: time + " " + hour,
            day: new Date(receivedMessage.insertTs),
          };
          setMessages((prevMessages) => [...prevMessages, formattedMessage]);
        }
        updateContentForUserId(receivedMessage.userId, receivedMessage.content);
        const list = JSON.parse(localStorage.getItem("room") || "[]");
        const useridlist = list.map((item: any) => item.userId);
        const isUserIdInList = useridlist.includes(receivedMessage.userId);
        if (!isUserIdInList) {
          readroom();
        }
      }
    };
  }, [selectroom]);

  const updateContentForUserId = (
    userIdToUpdate: number,
    newContent: string
  ) => {
    setContent((prevContent) =>
      prevContent.map((item) => ({
        userId: item.userId,
        content: item.userId === userIdToUpdate ? newContent : item.content,
      }))
    );
  };
  useEffect(() => {
    if (bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // 메시지 전송
  const sendMessage = () => {
    const nickname = localStorage.getItem("nick");
    if (inputMessage !== "") {
      const role = localStorage.getItem("role");
      let messageContent = inputMessage.replace(/\n/g, "<br>");
      let time = "";
      let hour = "";
      const sentTime = new Date();
      if (sentTime.getHours() < 12) {
        if (sentTime.getMinutes() < 9) {
          time = "오전";
          hour = sentTime.getHours() + ":0" + sentTime.getMinutes();
        } else {
          time = "오전";
          hour = sentTime.getHours() + ":" + sentTime.getMinutes();
        }
      } else {
        if (sentTime.getMinutes() < 9) {
          time = "오전";
          hour = sentTime.getHours() - 12 + ":0" + sentTime.getMinutes();
        } else {
          time = "오전";
          hour = sentTime.getHours() - 12 + ":" + sentTime.getMinutes();
        }
      }
      if (role === "ADMIN") {
        if (selectroom !== 0) {
          const message = {
            actionType: "CHAT",
            param: {
              chatType: "TALK",
              userId: selectroom,
              content: messageContent,
            },
          };
          ws.current?.send(JSON.stringify(message));
          setInputMessage("");
          const sentMessage = {
            sender: false,
            text: inputMessage,
          };
          const formattedMessage = {
            sender: sentMessage.sender,
            text: sentMessage.text.replace(/\n/g, "<br>"),
            time: time + " " + hour,
            day: sentTime,
            nick: nickname,
          };
          setMessages((prevMessages) => [...prevMessages, formattedMessage]);
        }
      } else {
        const message = {
          actionType: "CHAT",
          param: {
            chatType: "TALK",
            userId: 1,
            content: messageContent,
          },
        };
        ws.current?.send(JSON.stringify(message));
        setInputMessage("");
        const sentMessage = {
          sender: false,
          text: inputMessage,
        };
        const formattedMessage = {
          sender: sentMessage.sender,
          text: sentMessage.text.replace(/\n/g, "<br>"),
          time: time + " " + hour,
          day: sentTime,
          nick: nickname,
        };
        setMessages((prevMessages) => [...prevMessages, formattedMessage]);
      }
    }
  };

  // 채팅방 목록 조회
  const readroom = async () => {
    try {
      const list = await getroomlist(accessToken!, refreshToken!);
      setRoomList(list.data);
      localStorage.setItem("room", JSON.stringify(list.data));
      setContent(
        list.data.map((item: any) => ({
          userId: item.userId,
          content: item.content,
        }))
      );
    } catch (error) {
      console.error("채팅방 목록 조회 오류:", error);
    }
  };

  // 채팅방 선택 처리
  const handleRoomSelection = (userId: number, index: number) => {
    admingetchating(userId);
    setSelectRoom(userId);
    setSelectedRoomIndex(index);
  };

  // 클라이언트 채팅 내역 조회
  const getchating = async () => {
    try {
      const chat = await getchatingclient(accessToken!, refreshToken!);
      const formattedMessages = chat.data.map((msg: any) => {
        const messageTime = new Date(msg.insertTs);
        if (messageTime.getHours() < 12) {
          const formattedTime = `오전 ${messageTime.getHours()}:${(
            "0" + messageTime.getMinutes()
          ).slice(-2)}`;
          return {
            sender: msg.role === "ADMIN",
            text: msg.content,
            time: formattedTime,
            day: messageTime,
            nick: msg.nickname,
          };
        } else {
          messageTime.setHours(messageTime.getHours() - 12);
          const formattedTime = `오후 ${messageTime.getHours()}:${(
            "0" + messageTime.getMinutes()
          ).slice(-2)}`;
          return {
            sender: msg.role === "ADMIN",
            text: msg.content,
            time: formattedTime,
            day: messageTime,
            nick: msg.nickname,
          };
        }
      });

      setMessages(formattedMessages.reverse());
      if (role !== "ADMIN") {
        const enterMessage = {
          actionType: "CHAT",
          param: {
            chatType: "ENTER",
            userId: 1,
            content: "",
          },
        };
        webSocket.current?.send(JSON.stringify(enterMessage));
      }
    } catch {
      // 에러 처리
      getchating();
    }
  };

  // 어드민 채팅 내역 조회
  const admingetchating = async (userid: number) => {
    const chat = await getchatingadmin(accessToken!, refreshToken!, userid);
    const formattedMessages = chat.data.map((msg: any) => {
      const messageTime = new Date(msg.insertTs);
      if (messageTime.getHours() < 12) {
        const formattedTime = `오전 ${messageTime.getHours()}:${(
          "0" + messageTime.getMinutes()
        ).slice(-2)}`;

        return {
          sender: msg.role !== "ADMIN",
          text: msg.content,
          time: formattedTime,
          day: messageTime,
          nick: msg.nickname,
        };
      } else {
        messageTime.setHours(messageTime.getHours() - 12);
        const formattedTime = `오후 ${messageTime.getHours()}:${(
          "0" + messageTime.getMinutes()
        ).slice(-2)}`;
        return {
          sender: msg.role !== "ADMIN",
          text: msg.content,
          time: formattedTime,
          day: messageTime,
          nick: msg.nickname,
        };
      }
    });
    setMessages(formattedMessages.reverse());
  };

  // 시간 표시 여부 체크
  const shouldDisplayTime = (messages: any[], currentIndex: number) => {
    try {
      if (messages[currentIndex].sender != messages[currentIndex + 1].sender) {
        return true;
      } else if (
        messages[currentIndex].sender == messages[currentIndex + 1].sender
      ) {
        if (messages[currentIndex].time != messages[currentIndex + 1].time) {
          return true;
        }
      }
    } catch {}
    if (currentIndex === messages.length - 1) {
      return true;
    }
  };

  // 키보드 입력 처리
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    } else if (e.key === "Enter" && e.shiftKey) {
      // Shift + Enter 처리
    }
  };

  // 날짜가 달라지는지 체크
  const daydifferent = (messages: any[], currentIndex: number) => {
    try {
      const currentMessageDate = messages[currentIndex - 1].day.getDate();
      const currentDate = messages[currentIndex].day.getDate();
      return currentDate !== currentMessageDate;
    } catch {
      if (currentIndex === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  // 날짜 포맷 변환
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}년 ${month}월 ${day}일`;
  };

  // 닉네임이 달라지는지 체크
  const nickdifferent = (messages: any[], currentIndex: number) => {
    try {
      if (messages[currentIndex].time != messages[currentIndex - 1].time) {
        return true;
      }

      if (messages[currentIndex].sender != messages[currentIndex - 1].sender) {
        return true;
      } else if (
        messages[currentIndex].sender != messages[currentIndex + 1].sender
      ) {
        return true;
      }
    } catch {}
  };

  return (
    <Body title="고객센터" breadcrumb={["홈", "고객센터", "1:1문의"]}>
      <div
        className="box-container"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          height: "84vh",
        }}
      >
        {role !== null ? (
          role === "ADMIN" ? (
            <div className="list-popup">
              <div className="list-popup-header">
                <span>채팅 목록</span>
              </div>
              <div className="list-popup-body">
                {roomList.map((room, index) => (
                  <button
                    key={index}
                    className={`list-message ${
                      selectedRoomIndex === index
                        ? "listreceiver"
                        : "listsender"
                    }`}
                    onClick={() => handleRoomSelection(room.userId, index)}
                  >
                    {room.email} ({room.pcKorName}) <br />
                    <span style={{ fontWeight: "normal", fontSize: "smaller" }}>
                      {content[index].content.split("<br>")[0]}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <div></div>
          )
        ) : (
          <div></div>
        )}
        <div className="chat-popup">
          <div className="chat-popup-header">
            <span>실시간 채팅</span>
          </div>
          <div className="chat-popup-body">
            {messages.map((message, index) => (
              <React.Fragment key={index}>
                {daydifferent(messages, index) ? (
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    -{formatDate(message.day)}-
                  </div>
                ) : (
                  <></>
                )}
                {message.nick !== "" ? (
                  nickdifferent(messages, index) && (
                    <div
                      className={`${!message.sender ? "nickstart" : "nickend"}`}
                    >
                      {message.nick}
                    </div>
                  )
                ) : (
                  <div></div>
                )}
                <div
                  key={index}
                  className={`${
                    !message.sender ? "messagestart" : "messageend"
                  }`}
                >
                  {!message.sender ? (
                    shouldDisplayTime(messages, index) && (
                      <div className="timereceiver">{message.time}</div>
                    )
                  ) : (
                    <></>
                  )}
                  <div
                    className={`message ${
                      !message.sender ? "sender" : "receiver"
                    }`}
                  >
                    {message.text.split("<br>").map((line, lineIndex) => (
                      <React.Fragment key={lineIndex}>
                        {line}
                        {lineIndex !==
                          message.text.split("<br>").length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </div>
                  {message.sender ? (
                    shouldDisplayTime(messages, index) && (
                      <div className="timereceiver">{message.time}</div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </React.Fragment>
            ))}
            <div ref={bottomOfChatRef}></div>
          </div>
          <div className="chat-popup-footer">
            <textarea
              className="message-input"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="메시지를 입력하세요"
            />
          </div>
        </div>
      </div>
    </Body>
  );
};

export default Chating;
