import React, { useEffect } from "react";
import "../../styles/common/style.css";
import logo from "../../assets/images/logo.png";

// AddScreenShotProps 인터페이스 정의
interface AddScreenShotProps {
  closeModal: () => void; // 모달을 닫는 함수
  modalData: string; // 모달에 표시될 데이터 (이미지 URL)
  size: number; // 모달의 크기
  text: string; // 표시될 텍스트
  time: number; // 타이머 시간
  timetext:string;
}

// AddScreenShot 컴포넌트 정의
const AddScreenShot: React.FC<AddScreenShotProps> = ({
  closeModal,
  modalData,
  size,
  text,
  time,
  timetext,
}) => {
  // 모달 외부 클릭 시 모달 닫기 이벤트 핸들러 등록
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // 모달 외부 클릭 시 모달 닫기
  const handleClickOutside = (event: MouseEvent) => {
    if ((event.target as HTMLElement).classList.contains("modal-background")) {
      closeModal();
    }
  };

  return (
    <div className="modal-background">
      {size === 0 ? (
        // 작은 모달의 경우
        <div className="modal-content modal-scroll" style={{ height: "660px" }}>
          <div className="card__container">
            <div className="tableWrap">
              <img
                src={modalData}
                alt="이미지"
                style={{
                  width: "920px",
                  borderRadius: "10px",
                  marginLeft: "3px",
                }}
              />
              <div className="button-group">
                <button
                  className="button-close"
                  onClick={closeModal}
                  style={{ marginTop: "10px" }}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // 큰 모달의 경우
        <div
          className="modal-content"
          style={{ height: "1080px", width: "1950px" }}
        >
          <div className="card__container">
            <div className="tableWrap">
              {modalData ===
              "https://bora-asset-resource.s3.ap-northeast-2.amazonaws.com/common/logo/bora_logo_2_icon.png" ? (
                // 특정 이미지 URL에 대한 처리
                <>
                  <div
                    className="timer-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                   <span
                      className="card-text"
                      style={{
                        height: "100px",
                        fontSize: "70px",
                        background: "rgba(255, 255, 125, 1)",
                        marginTop: "40px",
                        marginLeft: "21px",
                      }}
                    >
                      <br />
                      <br />
                      &nbsp;&nbsp;{text}&nbsp;&nbsp;
                    </span>
                    <span
                      className="card-text"
                      style={{
                        height: "100px",
                        fontSize: "70px",
                        background: "rgba(255, 255, 125, 1)",
                        marginTop: "150px",
                        marginLeft: "21px",
                      }}
                    >
                      <br />
                      <br />
                      &nbsp;&nbsp;{timetext}&nbsp;&nbsp;
                    </span>
                    <svg
                      className="timer-svg"
                      style={{ marginLeft: "1650px", marginTop: "142px" }}
                    >
                      <circle
                        className="timer-circle-bg"
                        cx="50%"
                        cy="50%"
                        r="70"
                      />
                      <circle
                        className="timer-circle-gauge"
                        cx="50%"
                        cy="50%"
                        r="70"
                        style={{
                          strokeDasharray: `${(time / 5) * 440}px 440px`,
                          transition: "stroke-dasharray 0.1s linear",
                        }}
                      />
                      <text
                        className="timer-text"
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize="24px"
                        fill="#000"
                      >
                        {(time/10)}
                      </text>
                    </svg>
                  </div>
                  <img
                    src={logo}
                    alt="이미지"
                    style={{
                      width: "900px",
                      height: "800px",
                      borderRadius: "10px",
                      marginLeft: "450px",
                      marginTop: "130px",
                    }}
                  />
                </>
              ) : (
                // 일반적인 이미지 URL에 대한 처리
                <>
                  <div
                    className="timer-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="card-text"
                      style={{
                        height: "100px",
                        fontSize: "70px",
                        background: "rgba(255, 255, 125, 1)",
                        marginTop: "40px",
                        marginLeft: "21px",
                      }}
                    >
                      <br />
                      <br />
                      &nbsp;&nbsp;{text}&nbsp;&nbsp;
                    </span>
                    <span
                      className="card-text"
                      style={{
                        height: "100px",
                        fontSize: "70px",
                        background: "rgba(255, 255, 125, 1)",
                        marginTop: "150px",
                        marginLeft: "21px",
                      }}
                    >
                      <br />
                      <br />
                      &nbsp;&nbsp;{timetext}&nbsp;&nbsp;
                    </span>
                    <svg
                      className="timer-svg"
                      style={{ marginLeft: "1650px", marginTop: "142px" }}
                    >
                      <circle
                        className="timer-circle-bg"
                        cx="50%"
                        cy="50%"
                        r="70"
                        style={{ fill: "white", stroke: "rgba(0, 0, 0, 0.1)" }}
                      />
                      <circle
                        className="timer-circle-gauge"
                        cx="50%"
                        cy="50%"
                        r="70"
                        style={{
                          strokeDasharray: `${(time / 50) * 440}px 440px`,
                          transition: "stroke-dasharray 0.1s linear",
                        }}
                      />
                      <text
                        className="timer-text"
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize="24px"
                        fill="#000"
                      >
                        {(time/10)}
                      </text>
                    </svg>
                  </div>
                  <img
                    src={modalData}
                    alt="이미지"
                    style={{
                      width: "1840px",
                      height: "930px",
                      borderRadius: "10px",
                      marginLeft: "0px",
                    }}
                  />
                </>
              )}
              <div className="button-group">
                <button
                  className="button-close"
                  onClick={closeModal}
                  style={{ marginTop: "10px" }}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddScreenShot;
